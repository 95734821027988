import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const Aboutus = () => {
    return (
        <>

            <section style={{ marginTop: '5rem' }} className='py-3'>
                <Container>
                    <div className="data-analytics-outer">
                        <div className="data-main-analytics-inner">
                            <Row className='align-items-center justify-content-center'>
                                <Col lg={10}>
                                    <h4 className='head-fot-di'>
                                        About Us
                                    </h4>
                                    <p>
                                        We are Integral Analytics Solutions, and we have a passion for transforming how businesses use information and data to achieve success. With a focus on excellence and a love to invent, we help businesses succeed in the age of data-driven innovation.
                                    </p>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={12} >
                            <div className="d-flex align-items-start justify-content-center">
                                {/* <div className="data-migration-slide mt-2"></div> */}
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        Timeless Values Embraced by Cutting-Edge Technology
                                    </h5>
                                    <p>
                                        Integral Analytics Solutions holds an unwavering commitment to ethical values that are ingrained in the very foundation of our company. We are committed to the belief that success must be guided by principles that encourage positive change in our employees, our customers, and society as a whole. These moral pillars do not just impact our business, but they can also be the driving guide for our journey in the area in data analysis.
                                    </p>
                                    <p>
                                        <p>
                                            This method guarantees our customers receive not only the benefits of the latest technology but also the confidence that all of our offerings are built on a solid ethical basis and offer them the most beneficial of both worlds when they take their first steps on a data-driven path to success.
                                        </p>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="d-flex align-items-start justify-content-center">
                                {/* <div className="data-migration-slide mt-2"></div> */}
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        A Practical and Rational Approach to Privacy
                                    </h5>
                                    <p>
                                        Integral Analytics, we stand for a pragmatic and rational policy on privacy, one that places the individual first. In the age of data that is becoming more prevalent We recognize the vital importance of protecting people's private information as well as ensuring the proper use of information. Our adherence to privacy standards is not merely based on regulations, but rather by an underlying concern for the security of our clients as well as their customers.
                                    </p>
                                    <p>
                                    We are proactive in implementing strong privacy safeguards that reflect the common sense and ethical responsibilities and ensure that information is handled with respect, care and a strict security. In putting privacy first in a sensible and pragmatic manner, we encourage confidence and transparency in an age where these characteristics are crucial. Integral Analytics is a trusted partner. Integral Analytics, you're not only receiving a service, you're joining a company that respects privacy as a principle, while ensuring the security of your personal data as well as the confidence of your partners
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="d-flex align-items-start justify-content-center">
                                {/* <div className="data-migration-slide mt-2"></div> */}
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                    Revolutionizing Business Operations
                                    </h5>
                                    <p>
                                    Redefining business operations through Integral Analytics is about embracing an era that is based on strategic decisions. Through harnessing the power of data, we're helping businesses move to a place where data-driven insights can be acted upon immediately and allow businesses to stay ahead of the trend. Our data-driven suite of solutions allows organizations to improve their processes, make better decisions, and improve efficiency in operations
                                    </p>
                                    <p>
                                    Our services are tailored to meet the specific challenges and requirements of each customer, providing an individual approach that encourages the growth and development of. By focusing on the pursuit of transparency, innovation and constant learning, we're creating the future of data that isn't just a tool but a force to achieve success. Integral Analytics will be your committed partner who will help businesses take their business on transformational journeys that bring efficiency, knowledge and efficiency at the top of business operations.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="d-flex align-items-start justify-content-center">
                                {/* <div className="data-migration-slide mt-2"></div> */}
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                    A Privately-Owned Company with a Global Vision
                                    </h5>
                                    <p>
                                    In Integral Analytics Solutions' goal is to change the field of data analytics by creating an environment where data-driven insights enable businesses to flourish and develop. We aim to stay at the cutting edge of technological advances and provide innovative, custom solutions that turn raw information into useful insights that ultimately create a world where every decision is an informed one and the success of a company knows no boundaries.
                                    </p>
                                    <p>
                                    Our dedication to transparency, excellence, and continual learning has led us toward a future where data isn't just a source of information but a force to achieve achievement. Integral Analytics can be your devoted partner in guiding businesses on an exciting journey that brings efficiency, knowledge, and superiority at the top of business operations.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


        </>
    )
}