import React from "react";
import './test.css'
import { Col, Container, Row } from "react-bootstrap";

export const Testing = () =>{
    return(
        <>
        <section>
            <Container>
                <Row>
                    <Col lg={6}>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    )
}