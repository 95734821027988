import React from "react";
import { Container, Row } from "react-bootstrap";

export const Privacy = () => {
    return (
        <>
            <section style={{ marginTop: '6rem' }}>
                <Container>
                    <Row className="text-start">
                        <h5>
                            Privacy Policy for Smart InteGraphics
                        </h5>
                        <p>
                            At Smart InteGraphics, accessible from <a href="https://smartintegraphics.com/">https://smartintegraphics.com/</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy statement contains types of information that are collected and recorded by Smart InteGraphics and how we use it.
                        </p>
                        <p>
                            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                        </p>
                        <p>
                            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Smart InteGraphics. This policy does not apply to any information collected offline or via channels other than this website.
                        </p>
                        <h5>
                            Consent
                        </h5>
                        <p>
                            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                        </p>
                        <h5>
                            Information we collect
                        </h5>
                        <p>
                            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
                        </p>
                        <p>
                            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
                        </p>
                        <p>
                            When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
                        </p>
                    </Row>
                </Container>

            </section>

            <section>
                <Container>
                    <Row className="text-start">
                        <h5>How we use your information</h5>
                        <p>We use the information we collect in various ways, including to:</p>
                        {/* <ul> */}
                            <li>
                                Provide, operate, and maintain our website
                            </li>
                            <li>
                                Improve, personalize, and expand our website
                            </li>
                            <li>
                                Understand and analyze how you use our website
                            </li>
                            <li>
                                Develop new products, services, features, and functionality
                            </li>
                        {/* </ul> */}
                        <p>
                            Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes
                        </p>
                        <p>
                            Smart InteGraphics follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files includes internet protocol ( IP ) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any personally identifiable information. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Online Privacy Policy Generator.
                        </p>
                        <h5>
                            Advertising Partners Privacy Policies
                        </h5>
                        <p>
                            You may consult this list to find the Privacy Policy for each of the advertising partners of Smart InteGraphics.
                        </p>
                        <p>
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Smart InteGraphics, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                        </p>
                        <p>
                        <strong>Note:</strong> that Smart InteGraphics has no access to or control over these cookies that are used by third-party advertisers.
                        </p>
                        <h5>
                        Third Party Privacy Policies
                        </h5>
                        <p>
                        Smart InteGraphics's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                        </p>
                        <p>
                        You can choose to disable cookies through your browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                        </p>
                        <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
                        </p>
                    </Row>
                </Container>
            </section>
        </>
    )
}