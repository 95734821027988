import React from 'react'
import './dataanalytics.css'
import { Accordion, Col, Container, Row } from 'react-bootstrap'

export const DataAnalytics = () => {
    return (
        <div>

            <section style={{ marginTop: '5rem' }} className='py-3'>
                <Container>
                    <div className="data-analytics-outer">
                        <div className="data-main-analytics-inner">
                            <Row className='align-items-center justify-content-center'>
                                <Col lg={10}>
                                    <h5 className='head-fot-di'>
                                        Discover the Power of Data Analytics
                                    </h5>
                                    <p>
                                        Smart Integraphics application offers a wide range of connectors for various data sources. These include file formats such as Excel, CSV, TXT, JSON, and XML. For databases, it supports SQL, Oracle, MySQL, MongoDB, and PostgreSQL. Additionally, it integrates seamlessly with popular ERP systems like SAP, Oracle, Microsoft Dynamics, S/4HANA, and IFS. With such a diverse set of integration options, Smart Integraphics provides users with extensive flexibility and versatility for their data analytics needs
                                    </p>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'  >
                        <Col lg={12} className=' px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Customizable Dashboards
                                    </h5>
                                    <p >
                                        The Smart Integraphics tool allows users to create personalized dashboards by selecting relevant charts, graphs, and data points. Its intuitive drag-and-drop functionality makes customization easy, without any technical expertise or coding knowledge. By integrating with various data sources, it provides real-time insights and enhances strategic decision-making. Smart Integraphics transforms complex data into clear, actionable visualizations, boosting productivity, collaboration, and enabling data-driven decisions for better outcomes.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/Chart&dashboard-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Chart&dashboard-2.gif')} className='w-100' alt='interactive-dashboard' />
                            {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/Chart&dashboard-2.gif' className='w-100' alt='interactive-dashboard' /> */}
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>

                        <Col lg={12}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3 ">
                                    <h5 className='head-fot-di' >
                                        Auto-Suggested Charts
                                    </h5>
                                    <p >
                                        Auto-suggested charts in BI tools automatically recommend the most relevant visualizations based on the data selected by the user. These suggestions leverage algorithms to identify the best chart types (e.g., bar, line, pie) that effectively communicate the underlying trends and patterns. This feature streamlines the data analysis process, saving users time and ensuring that insights are presented in the most impactful way. By offering smart recommendations, auto-suggested charts enhance data exploration and decision-making efficiency
                                    </p>


                                </div>
                            </div>
                        </Col>

                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/Chart&dashboard-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                           <img src={require('../assets/data-analytics/Compress/AI Chart Suggestion.gif')} className='w-100' alt='interactive-dashboard' />
                           {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/AI Chart Suggestion.gif' className='w-100' alt='interactive-dashboard' /> */}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>

                        <Col lg={12} className='px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Uncover insights with AI
                                    </h5>
                                    <p >
                                        AI insights on data use machine learning and advanced algorithms to analyze large datasets, uncover hidden patterns, and predict future trends. These insights help businesses identify new opportunities, optimize operations, and make data-driven decisions. AI can automate data analysis, reducing manual effort and improving efficiency. It also provides actionable recommendations, enabling proactive decision-making. Overall, AI insights enhance business intelligence by delivering deeper, more accurate insights faster.
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/AI-Insights-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/AI Insights.gif')} className='w-100' alt='interactive-dashboard'  />
                            {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/AI Insights.gif' className='w-100' alt='interactive-dashboard'  /> */}
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Predictive Analytics
                                    </h5>
                                    <p >
                                        Predictive analytics uses historical data, statistical algorithms, and machine learning techniques to identify the likelihood of future outcomes based on patterns in past data. This approach helps organizations forecast trends, behaviors, and potential events, allowing them to make data-driven predictions, optimize strategies, improve risk management, and informed decisions that enhance operational efficiency, profitability, and competitive advantage. By leveraging advanced modeling techniques, predictive analytics also helps identify emerging opportunities, streamline processes, and foster innovation across various industries.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}  >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/predictive-analytics.gif')} className='w-100' alt='interactive-dashboard' />
                            {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/Mail-DB-2.gif' className='w-100' alt='interactive-dashboard' /> */}
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12} className='px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Data Drill-Down
                                    </h5>
                                    <p>
                                        Users can explore data at different levels of granularity by drilling down into more detailed information. This allows them to navigate from high-level summary data to specific data points, uncovering underlying trends, patterns, and actionable insights. By drilling down, users can gain a deeper understanding of individual elements, such as sales performance by region or product, helping to identify root causes, inefficiencies, or opportunities for improvement and strategic decision-making.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/Mail-DB-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/DRILL-DOWN.gif')} className='w-100' alt='interactive-dashboard' />
                            {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/DRILL-DOWN.gif' className='w-100' alt='interactive-dashboard' /> */}
                        </Col>


                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Sharing and Granting Access to Dashboards and Reports
                                    </h5>
                                    <p>
                                        Sharing and granting access to dashboards and reports allows users to securely distribute insights to stakeholders and team members based on their roles. Smart Integraphics provide features to control access permissions, such as view-only or edit access, ensuring that sensitive data is protected. Users can share reports via email or embedded within other platforms, promoting collaboration and efficient decision-making. This feature empowers teams to stay aligned with real-time data while maintaining data security and privacy through role-based access controls.
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Mail_DB.gif')} className='w-100' alt='interactive-dashboard' />
                            {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/Mail_DB.gif' className='w-100' alt='interactive-dashboard' /> */}
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12} className='px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>
                                        Natural Language Processing (NLP)
                                    </h5>
                                    <p>
                                        Natural Language Processing (NLP) in Smart Integraphics allows users to interact with data using natural language, such as asking questions like "What were the sales last month?" or "Show me the top-performing products." It simplifies data querying for non-technical users, eliminating the need for complex coding or SQL knowledge. NLP enhances data accessibility, enabling anyone in the organization to quickly derive insights. This feature speeds up decision-making by providing instant, actionable answers in the form of reports or visualizations
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/NLP.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            {/* <img src={require('../assets/data-analytics/NLP.')} */}
                            <img src={require('../assets/data-analytics/Compress/NLP.gif')} className='w-100' alt='interactive-dashboard' />
                            {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/NLP.gif' className='w-100' alt='interactive-dashboard' /> */}
                        </Col>



                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Data Alerts and Notifications
                                    </h5>
                                    <p>
                                        Data alerts and notifications in Smart Integraphics automatically inform users when specific conditions or thresholds are met, such as when sales reach a target or inventory drops below a set level. This feature enables proactive decision-making by alerting users to critical changes in real time. It enhances efficiency by reducing manual monitoring and ensuring key events are never missed. Automated notifications help users stay on track with business goals by delivering timely insights. Overall, data alerts empower quicker, data-driven actions for improved business performance
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}  >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Mail_DB.gif')} className='w-100' alt='interactive-dashboard' />
                            {/* <img src='https://smartintegraphics.com/assets/data-analytics/Compress/Mail_DB.gif' className='w-100' alt='interactive-dashboard' /> */}
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section>
                <Container>
                    <Row>
                        <Col lg={12} className='text-start'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" flush>
                                    <Accordion.Header><h5>Retail Organization: Sales Performance Analysis</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            <strong>Use Case:</strong> A retail organization wants to understand its sales performance across different regions, product categories, and time periods. By analyzing historical sales data, the company can identify trends, seasonality, and product preferences.
                                        </p>
                                        <p>
                                            <strong>Description:</strong>The organization uses analytics to generate reports on total sales, sales growth, and performance by store location. This helps the marketing and sales teams understand the effectiveness of promotional campaigns, inventory levels, and customer demand patterns.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>Retail managers gain insights into peak shopping periods, regional performance differences, and popular product categories, which helps optimize inventory and marketing strategies.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><h5>Financial Institution: Historical Transaction Analysis</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A bank uses  analytics to analyze historical transaction data to understand spending behavior, account activity, and identify trends in customer behavior.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            By analyzing customer transaction history, account balances, and loan histories, the bank generates reports on transaction volumes, deposit patterns, and loan repayment trends. The bank also examines the frequency of withdrawals and deposits by account type.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            This analysis enables the bank to evaluate the health of its customer base, track the adoption of new products, and identify cross-sell opportunities, such as suggesting loans to customers with a history of consistent deposits.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><h5>Healthcare Organization: Patient Outcomes and Treatment Effectiveness</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A healthcare provider analyzes patient data to evaluate the effectiveness of treatments, patient recovery rates, and patterns of hospital readmissions.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The healthcare provider uses historical patient data (e.g., diagnosis, treatment plans, recovery times) to generate reports that describe the most common treatments for specific conditions, treatment success rates, and the average length of patient stay. This data can also highlight recurring patterns related to chronic diseases or patient demographics.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            The hospital gains valuable insights into the success of its medical treatments, helping to standardize care protocols, reduce readmission rates, and improve patient outcomes.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><h5>Manufacturing: Production Efficiency and Quality Control</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A manufacturing company analyzes historical production data to assess efficiency, product quality, and defect rates.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            By reviewing production logs, quality inspection reports, and equipment performance data, the company generates reports on the number of units produced per day, defect rates, and machine downtime. The analysis identifies common causes of defects or inefficiencies in the production line.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            The company can identify areas for process improvement, optimize maintenance schedules, and take corrective actions to reduce production bottlenecks or defects, ultimately improving product quality and reducing waste.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><h5>Telecommunications: Call Data Analysis</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A telecommunications company wants to analyze historical call data to better understand network performance, customer usage patterns, and service quality.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The company uses  analytics to summarize call volume, average call duration, and network congestion over time. It analyzes customer complaint logs, call drop rates, and regional network performance.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            By identifying peak call times, areas with frequent service issues, and trends in customer complaints, the company can allocate resources more effectively, improve network capacity, and enhance customer satisfaction.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><h5>E-Commerce: Customer Behavior Analysis</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>An e-commerce platform analyzes customer behavior data to understand purchase patterns, browsing habits, and customer segmentation.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The e-commerce company reviews historical data on website visits, abandoned carts, and completed transactions to summarize which products are most frequently purchased, customer segments, and conversion rates across different channels (e.g., mobile app vs. website).
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            The company identifies trends such as peak shopping times, popular product categories, and customer preferences, allowing for improved product recommendations, personalized marketing campaigns, and targeted promotions.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><h5>Education: Student Enrollment and Performance Analysis</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A university uses  analytics to analyze student enrollment patterns, academic performance, and retention rates over several years.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The institution generates reports based on student data, such as grades, class attendance, graduation rates, and demographic information. The university may also look at trends in course popularity and program enrollment.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            The university identifies trends in student success and retention, and can adjust its offerings based on the analysis (e.g., adding popular courses, improving support for at-risk students, or revising admission criteria).
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header><h5> Hospitality: Guest Satisfaction and Operational Efficiency</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A hotel chain uses  analytics to track guest satisfaction scores, booking patterns, and seasonal occupancy rates.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The hotel analyzes historical data from guest feedback surveys, booking history, and room occupancy to understand the most common reasons for guest satisfaction or dissatisfaction. The hotel chain can summarize how occupancy rates fluctuate based on seasonality, location, and promotional campaigns.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            By understanding customer satisfaction drivers and occupancy trends, the hotel chain can improve guest services, optimize pricing strategies, and plan for busy seasons more effectively.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="8">
                                    <Accordion.Header><h5> Supply Chain: Inventory and Demand Analysis</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A logistics company uses  analytics to evaluate inventory levels, order fulfillment times, and supplier performance.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The company summarizes historical data on stock levels, order processing times, and supplier delivery performance to understand how well its supply chain operates. The analysis includes identifying which products are frequently out of stock, and which suppliers tend to have longer delivery times.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            The company can improve demand forecasting, reduce stockouts, and optimize the procurement process by working more efficiently with reliable suppliers.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="9">
                                    <Accordion.Header><h5> Government: Tax Revenue and Budget Analysis</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A government agency uses  analytics to track tax revenue, government spending, and budget allocations over time.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            By analyzing historical data from tax collection, departmental spending, and public services, the government can generate reports on revenue trends, spending patterns, and discrepancies in budget allocation.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            The agency gains insights into which tax revenues are increasing or decreasing and which public sectors require more funding, allowing for better budget planning and more efficient allocation of resources.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="10">
                                    <Accordion.Header><h5> Transportation: Traffic Analysis and Road Usage Patterns</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A city's transportation department uses  analytics to analyze traffic data from sensors placed on roads and intersections.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The city analyzes historical traffic flow, accident reports, and road usage data to summarize peak traffic hours, high-traffic roads, and accident hotspots.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            This analysis enables better planning for road maintenance, traffic management, and the allocation of public resources to improve road safety and traffic flow.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="11">
                                    <Accordion.Header><h5> Real Estate: Property Value and Market Trends</h5></Accordion.Header>
                                    <Accordion.Body>
                                        <p><strong>Use Case: </strong>A real estate agency uses  analytics to understand property value trends, regional market conditions, and historical price changes.</p>
                                        <p>
                                            <strong>Description: </strong>
                                            The agency analyzes data on property sales, rental prices, and neighborhood demographics to summarize trends in real estate values and demand across various locations.
                                        </p>
                                        <p>
                                            <strong>Outcome: </strong>
                                            The agency can identify promising investment opportunities, track regional market trends, and offer informed advice to buyers and sellers.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className='py-4'>
                <Container>
                    <div className="data-analytics-outer">
                        <div className="data-main-analytics-inner">
                            <Row className='align-items-center justify-content-center'>
                                <Col lg={10}>
                                    <h4 className='head-fot-di'>
                                        Different Use Cases
                                    </h4>
                                    <p>
                                        Smart Infographics is used across various industries for data visualization and decision-making. In healthcare, they help present patient data trends for better care and operational insights. Retail businesses utilize Smart Infographics to showcase inventory patterns and targeted marketing strategies. In finance, they simplify complex financial data for risk assessment and investment analysis. Manufacturing industries use them to visualize supply chain metrics and monitor equipment performance. In education, Smart Infographics aid in displaying student performance data and enhancing institutional decision-making.
                                    </p>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col lg={12} className='text-start'>
                            <Row>
                                <Col lg={6}>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0" flush className=' acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'>Retail Organization: Sales Performance Analysis</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                    <strong>Use Case:</strong> A retail organization wants to understand its sales performance across different regions, product categories, and time periods. By analyzing historical sales data, the company can identify trends, seasonality, and product preferences.
                                                </p>
                                                <p>
                                                    <strong>Description:</strong>The organization uses analytics to generate reports on total sales, sales growth, and performance by store location. This helps the marketing and sales teams understand the effectiveness of promotional campaigns, inventory levels, and customer demand patterns.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>Retail managers gain insights into peak shopping periods, regional performance differences, and popular product categories, which helps optimize inventory and marketing strategies.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'>Financial Institution: Historical Transaction Analysis</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A bank uses  analytics to analyze historical transaction data to understand spending behavior, account activity, and identify trends in customer behavior.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    By analyzing customer transaction history, account balances, and loan histories, the bank generates reports on transaction volumes, deposit patterns, and loan repayment trends. The bank also examines the frequency of withdrawals and deposits by account type.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    This analysis enables the bank to evaluate the health of its customer base, track the adoption of new products, and identify cross-sell opportunities, such as suggesting loans to customers with a history of consistent deposits.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'>Healthcare Organization: Patient Outcomes and Treatment Effectiveness</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A healthcare provider analyzes patient data to evaluate the effectiveness of treatments, patient recovery rates, and patterns of hospital readmissions.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The healthcare provider uses historical patient data (e.g., diagnosis, treatment plans, recovery times) to generate reports that describe the most common treatments for specific conditions, treatment success rates, and the average length of patient stay. This data can also highlight recurring patterns related to chronic diseases or patient demographics.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    The hospital gains valuable insights into the success of its medical treatments, helping to standardize care protocols, reduce readmission rates, and improve patient outcomes.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'>Manufacturing: Production Efficiency and Quality Control</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A manufacturing company analyzes historical production data to assess efficiency, product quality, and defect rates.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    By reviewing production logs, quality inspection reports, and equipment performance data, the company generates reports on the number of units produced per day, defect rates, and machine downtime. The analysis identifies common causes of defects or inefficiencies in the production line.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    The company can identify areas for process improvement, optimize maintenance schedules, and take corrective actions to reduce production bottlenecks or defects, ultimately improving product quality and reducing waste.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'>Telecommunications: Call Data Analysis</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A telecommunications company wants to analyze historical call data to better understand network performance, customer usage patterns, and service quality.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The company uses  analytics to summarize call volume, average call duration, and network congestion over time. It analyzes customer complaint logs, call drop rates, and regional network performance.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    By identifying peak call times, areas with frequent service issues, and trends in customer complaints, the company can allocate resources more effectively, improve network capacity, and enhance customer satisfaction.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'>E-Commerce: Customer Behavior Analysis</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>An e-commerce platform analyzes customer behavior data to understand purchase patterns, browsing habits, and customer segmentation.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The e-commerce company reviews historical data on website visits, abandoned carts, and completed transactions to summarize which products are most frequently purchased, customer segments, and conversion rates across different channels (e.g., mobile app vs. website).
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    The company identifies trends such as peak shopping times, popular product categories, and customer preferences, allowing for improved product recommendations, personalized marketing campaigns, and targeted promotions.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                </Col>
                                <Col lg={6}>
                                    <Accordion defaultActiveKey="6">


                                        <Accordion.Item eventKey="6" className=' acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'>Education: Student Enrollment and Performance Analysis</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A university uses  analytics to analyze student enrollment patterns, academic performance, and retention rates over several years.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The institution generates reports based on student data, such as grades, class attendance, graduation rates, and demographic information. The university may also look at trends in course popularity and program enrollment.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    The university identifies trends in student success and retention, and can adjust its offerings based on the analysis (e.g., adding popular courses, improving support for at-risk students, or revising admission criteria).
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="7" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'> Hospitality: Guest Satisfaction and Operational Efficiency</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A hotel chain uses  analytics to track guest satisfaction scores, booking patterns, and seasonal occupancy rates.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The hotel analyzes historical data from guest feedback surveys, booking history, and room occupancy to understand the most common reasons for guest satisfaction or dissatisfaction. The hotel chain can summarize how occupancy rates fluctuate based on seasonality, location, and promotional campaigns.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    By understanding customer satisfaction drivers and occupancy trends, the hotel chain can improve guest services, optimize pricing strategies, and plan for busy seasons more effectively.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="8" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'> Supply Chain: Inventory and Demand Analysis</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A logistics company uses  analytics to evaluate inventory levels, order fulfillment times, and supplier performance.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The company summarizes historical data on stock levels, order processing times, and supplier delivery performance to understand how well its supply chain operates. The analysis includes identifying which products are frequently out of stock, and which suppliers tend to have longer delivery times.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    The company can improve demand forecasting, reduce stockouts, and optimize the procurement process by working more efficiently with reliable suppliers.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="9" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'> Government: Tax Revenue and Budget Analysis</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A government agency uses  analytics to track tax revenue, government spending, and budget allocations over time.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    By analyzing historical data from tax collection, departmental spending, and public services, the government can generate reports on revenue trends, spending patterns, and discrepancies in budget allocation.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    The agency gains insights into which tax revenues are increasing or decreasing and which public sectors require more funding, allowing for better budget planning and more efficient allocation of resources.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="10" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'> Transportation: Traffic Analysis and Road Usage Patterns</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A city's transportation department uses  analytics to analyze traffic data from sensors placed on roads and intersections.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The city analyzes historical traffic flow, accident reports, and road usage data to summarize peak traffic hours, high-traffic roads, and accident hotspots.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    This analysis enables better planning for road maintenance, traffic management, and the allocation of public resources to improve road safety and traffic flow.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="11" className='mt-2 acc-eac-it'>
                                            <Accordion.Header><p className='ac-anay-data'> Real Estate: Property Value and Market Trends</p></Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>Use Case: </strong>A real estate agency uses  analytics to understand property value trends, regional market conditions, and historical price changes.</p>
                                                <p>
                                                    <strong>Description: </strong>
                                                    The agency analyzes data on property sales, rental prices, and neighborhood demographics to summarize trends in real estate values and demand across various locations.
                                                </p>
                                                <p>
                                                    <strong>Outcome: </strong>
                                                    The agency can identify promising investment opportunities, track regional market trends, and offer informed advice to buyers and sellers.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>

        </div>
    )
}
