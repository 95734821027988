import React, { useState } from "react";
import { Accordion, Button, Col, Container, Row, Tab, Table, Tabs } from "react-bootstrap";
import './pricing.css'
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";

export const Pricing = () => {
    const [key, setKey] = useState('yearly');
    return (
        <div>
            <section className="mt-5 pt-5">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={6}>
                            <h5 className='head-fot-di'>Investment In Your Future</h5>
                            <p style={{fontSize:'16px',}}>
                                Unlock the business value of your data with an end-to-end tool that extracts,
                                integrates , clean and visualizes your data.
                            </p>
                        </Col>

                    </Row>
                </Container>
            </section>


            <section>
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={12}>

                            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}
                                className="price-data-head" style={{ marginBottom: '1rem' }}>

                                <Tab eventKey="yearly" title="Yearly" >
                                    <Row>
                                        <Col lg={12}>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0" >
                                                    <Accordion.Header ><h5 style={{fontSize:'17px', fontWeight:'600',}}>Data Integration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }} className="py-5"><p className="ta-he-pri">Features</p></th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">$49.99/Month</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$79.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri"> (Cloud)</p>
                                                                        {/* <h6></h6> */}
                                                                        {/* <p className="ta-he-pri">$99.99/Month</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p> */}
                                                                        {/* <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link> */}
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                        {/* <p className="ta-he-pri">$99.99</p> */}
                                                                        {/* <p className="ta-he-pri">10 User</p> */}
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion defaultActiveKey="0" className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title" >Files</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">XLS</td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">XLSX</td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">CSV</td>
                                                                                                <td className="price-inne-2" ><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">JSON</td>
                                                                                                <td className="price-inne-2" ><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title">DataBase</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">Oracle</td>
                                                                                                <td className="price-inne-2" ><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2" ><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start"> MS SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    MY SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    IBM DB2
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Maria Database
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Ms Access
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SQL Lite
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SQL Server
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Postgre SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Mongo DB
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    IBM netezza
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Teradata
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Azure SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title">ERP</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SAP ECC
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SAP HANA
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Oracle ERP
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    MS Dynamics
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title">Online Services</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    X
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Youtube
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Facebook
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    LinkedIn
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><h5 style={{fontSize:'17px', fontWeight:'600',}}>Data Migration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }} className="py-5"><p className="ta-he-pri">Features</p></th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">$49.99/Month</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$79.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise  </p>
                                                                        <p className="ta-he-pri">(Cloud)</p>
                                                                        
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                    {/* <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise(Cloud) </p>
                                                                       
                                                                        <p className="ta-he-pri">$99.99/Month</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th> */}
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                        {/* <p className="ta-he-pri">$99.99</p> */}
                                                                        {/* <p className="ta-he-pri">10 User</p> */}
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td className="text-start">SAP ECC</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">SAP HANA</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Oracle ERP</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">MS Dynamics</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Oracle</td>
                                                                    <td ><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        MS SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        MY SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        IBM DB2
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Maria Database
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Ms Access
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        SQL Lite
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        SQL Server
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Postgre SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Mongo DB
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        IBM netezza
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Teradata
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Azure SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header><h5 style={{fontSize:'17px', fontWeight:'600',}}>Data Analytics </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }} className="py-5"><p className="ta-he-pri">Features</p></th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">$49.99/Month</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$79.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise  </p>
                                                                        <p className="ta-he-pri">(Cloud)</p>
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                    {/* <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise(Cloud) </p>
                                                                        <h6></h6>
                                                                        <p className="ta-he-pri">$99.99/Month</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th> */}
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                        {/* <p className="ta-he-pri">$99.99</p> */}
                                                                        {/* <p className="ta-he-pri">10 User</p> */}
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td className="text-start">Charts</td>
                                                                    <td>16</td>
                                                                    <td>80</td>
                                                                    <td>200</td>
                                                                    <td>200</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Dash Boards</td>
                                                                    <td>2</td>
                                                                    <td>10</td>
                                                                    <td>25</td>
                                                                    <td>25</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Data pre-processing</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Auto Suggestion Charts (AI Driven)</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Download Dashboard as PDF</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Refresh Rate
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>Hourly</td>
                                                                    <td>As Nedded</td>
                                                                    <td>As Nedded</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        iMirAI[co-pilot]
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Advanced Data Preparation
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Data Quering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Personalized dashboards
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Fine tune on Built-in Algorithms
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Drill Down and Customize Charts
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Self-service Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        AI Generated Trends
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Vice-Activated Querying
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Smart Data Discovering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Automated Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-start">
                                                                        Visualization From Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Natural Language Questions
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Contexual Analysis
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-start">
                                                                        AI Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Mail Notification
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Mobile App
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                {/* <Accordion.Item eventKey="3">
                                                    <Accordion.Header><h5 style={{fontSize:'17px', fontWeight:'600',}}>Services </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }} className="py-5"><p className="ta-he-pri">Features</p></th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">Not Available</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$39.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise(Cloud) </p>
                                                                        
                                                                        <p className="ta-he-pri">$49.99/Month</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                       
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td className="text-start">Create Customer Instance (Generic)</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>10 Hrs</td>
                                                                    <td>20 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Business Process Workshop</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>10 Hrs</td>
                                                                    <td>20 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Configure Data Connections</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>10 Hrs</td>
                                                                    <td>20 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Data Transformation </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>80 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    Create Dashboards
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>40 Hrs</td>
                                                                    <td>100 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    Configure Filters, Personalization
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>80 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    iMirAI [Co-Pilot]
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>40 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    AI Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>40 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    Create Core Data Structure
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>40 Hrs</td>
                                                                    <td>80 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item> */}
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab eventKey="monthly" title="Monthly" >
                                    <Row>
                                        <Col lg={12}>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0" >
                                                    <Accordion.Header ><h5 style={{fontSize:'17px', fontWeight:'600',}}>Data Integration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }} className="pb-5"><p className="ta-he-pri">Features</p></th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">$65.99/Month</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$99.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    {/* <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise (Cloud) </p>
                                                                        <p className="ta-he-pri">$119.99/Month</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th> */}
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri"> (Cloud)</p>
                                                                
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion defaultActiveKey="0" className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title">Files</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">XLS</td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">XLSX</td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">CSV</td>
                                                                                                <td className="price-inne-2" ><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">JSON</td>
                                                                                                <td className="price-inne-2" ><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title">DataBase</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">Oracle</td>
                                                                                                <td className="price-inne-2" ><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start"> MS SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    MY SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    IBM DB2
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Maria Database
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Ms Access
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SQL Lite
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SQL Server
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Postgre SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Mongo DB
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    IBM netezza
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Teradata
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Azure SQL
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title">ERP</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SAP ECC
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    SAP HANA
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Oracle ERP
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    MS Dynamics
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <Accordion className="custom-accordion">
                                                                            <Accordion.Item eventKey="0" className="custom-accordion-item">
                                                                                <Accordion.Header className="custom-accordion-header"><h6 className="custom-accordion-title">Online Services</h6></Accordion.Header>
                                                                                <Accordion.Body className="custom-accordion-body">
                                                                                    <Table bordered>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    X
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Youtube
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    Facebook
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className="price-inne-2 text-start">
                                                                                                    LinkedIn
                                                                                                </td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CloseIcon className="close-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                                <td className="price-inne-2"><CheckIcon className="check-ico-price" /></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>

                                                                        </Accordion>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><h5 style={{fontSize:'17px', fontWeight:'600',}}>Data Migration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }} className="pb-5"><p className="ta-he-pri">Features</p></th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">$65.99/Month</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$99.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise  </p>
                                                                        <p className="ta-he-pri">(Cloud)</p>
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                    {/* <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise(Cloud) </p>
                                                                    
                                                                        <p className="ta-he-pri">$119.99/Month</p>
                                                                        <p className="ta-he-pri">10 User (Minium)</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th> */}
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                        {/* <p className="ta-he-pri">$99.99</p> */}
                                                                        {/* <p className="ta-he-pri">10 User</p> */}
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td className="text-start">SAP ECC</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">SAP HANA</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Oracle ERP</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">MS Dynamics</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Oracle</td>
                                                                    <td ><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        MS SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        MY SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        IBM DB2
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Maria Database
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Ms Access
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        SQL Lite
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        SQL Server
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Postgre SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Mongo DB
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        IBM netezza
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Teradata
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Azure SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header><h5 style={{fontSize:'17px', fontWeight:'600',}}>Data Analytics </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center ">
                                                                    <th style={{ width: '230px' }} className="pb-5">
                                                                        {/* <div className="d-flex align-items-center justify-content-center"> */}
                                                                        <p className="ta-he-pri">Features</p>
                                                                        {/* </div> */}

                                                                    </th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">$65.99/Month</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$99.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise  </p>
                                                                        <p className="ta-he-pri">(Cloud)</p>
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                    {/* <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise(Cloud)</p>
                                                                       
                                                                        <p className="ta-he-pri">$119.99/Month</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th> */}
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                        {/* <p className="ta-he-pri">$99.99</p> */}
                                                                        {/* <p className="ta-he-pri">10 User</p> */}
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td className="text-start">Charts</td>
                                                                    <td>16</td>
                                                                    <td>80</td>
                                                                    <td>200</td>
                                                                    <td>200</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Dash Boards</td>
                                                                    <td>2</td>
                                                                    <td>10</td>
                                                                    <td>25</td>
                                                                    <td>25</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Data pre-processing</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Auto Suggestion Charts (AI Driven)</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Download Dashboard as PDF</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Refresh Rate
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>Hourly</td>
                                                                    <td>As Nedded</td>
                                                                    <td>As Nedded</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        iMirAI[co-pilot]
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Advanced Data Preparation
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Data Quering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Personalized dashboards
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Fine tune on Built-in Algorithms
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Drill Down and Customize Charts
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Self-service Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        AI Generated Trends
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Vice-Activated Querying
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Smart Data Discovering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Automated Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-start">
                                                                        Visualization From Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Natural Language Questions
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Contexual Analysis
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td className="text-start">
                                                                        AI Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Mail Notification
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                        Mobile App
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                {/* <Accordion.Item eventKey="3">
                                                    <Accordion.Header><h5 style={{fontSize:'17px', fontWeight:'600',}}>Services </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }} className="py-5"><p className="ta-he-pri">Features</p></th>
                                                                    <th style={{ width: '230px' }}><p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Basic</p>
                                                                        <p className="ta-he-pri">Not Available</p>
                                                                        
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Basic' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Professional</p>
                                                                        <p className="ta-he-pri">$39.99/Month</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Professional' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise(Cloud) </p>
                                                                       
                                                                        <p className="ta-he-pri">$49.99/Month</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                        <Link to='https://smart.integraldataanalytics.com/getIGinfo?Lgtype=Enterprise' target="blank">
                                                                            <Button className="price-bt">Buy Now</Button>
                                                                        </Link>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <p className="ta-he-pri" style={{fontSize:'17px', fontWeight:'600',}}>Enterprise </p>
                                                                        <p className="ta-he-pri">(On-Premise)</p>
                                                                      
                                                                        <Link to='contact-us' target="blank">
                                                                            <Button className="price-bt">Contact Us</Button>
                                                                        </Link>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td className="text-start">Create Customer Instance (Generic)</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>10 Hrs</td>
                                                                    <td>20 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Business Process Workshop</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>10 Hrs</td>
                                                                    <td>20 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Configure Data Connections</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>10 Hrs</td>
                                                                    <td>20 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">Data Transformation </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>80 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    Create Dashboards
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>40 Hrs</td>
                                                                    <td>100 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    Configure Filters, Personalization
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>80 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    iMirAI [Co-Pilot]
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>40 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    AI Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>20 Hrs</td>
                                                                    <td>40 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start">
                                                                    Create Core Data Structure
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>40 Hrs</td>
                                                                    <td>80 Hrs</td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item> */}
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </section>



            <section className='mt-5 mb-3'>
                <Container>
                    <div className="cal-to-new-outer">
                        <div className="cal-inner">
                            <Row style={{ transform: 'rotate(0deg)' }} className='align-items-center justify-content-center '>
                                <Col lg={10}>
                                    <h5 className='head-fot-di'>
                                        FAQ's
                                    </h5>
                                    {/* <p>
                                        Unlock the full potential of your data with our innovative solution. Our product combines cutting-edge AI, seamless data integration, and advanced analytics to deliver actionable insights. Experience enhanced efficiency, smarter decision-making, and effortless data migration all in one platform. Take your business to the next level with a tool designed to drive growth and transform how you manage and utilize data
                                    </p> */}
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <Accordion defaultActiveKey="0" className="faq-price-acc">
                        <Accordion.Item eventKey="0" className="faq-price-acc-item">
                            <Accordion.Header className="faq-price-acc-header"><h5 style={{fontSize:'17px', fontWeight:'600',}}>What Is Smart IG?</h5></Accordion.Header>
                            <Accordion.Body className="faq-price-acc-body text-start">
                                <p style={{fontSize:'16px',}}>
                                Smart IG is a powerful platform that combines data migration, data integration, and data analytics into a single solution. It provides users with insightful visualizations and is supported by its AI-powered copilot, iMirAI, which enhances decision-making and user experience.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className="faq-price-acc-item">
                            <Accordion.Header className="faq-price-acc-header"><h5 style={{fontSize:'17px', fontWeight:'600',}}>Cloud vs On-premise: How to choose the right plan?</h5></Accordion.Header>
                            <Accordion.Body className="faq-price-acc-body text-start">
                                <p style={{fontSize:'16px',}}>
                                The decision hinges on several factors. Evaluate your organization's priorities in terms of security, cost, scalability, and maintenance to make an informed decision.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="faq-price-acc-item">
                            <Accordion.Header className="faq-price-acc-header"><h5 style={{fontSize:'17px', fontWeight:'600',}}>Is customization available for the on-cloud Smart IG?</h5></Accordion.Header>
                            <Accordion.Body className="faq-price-acc-body text-start">
                                <p style={{fontSize:'16px',}}>
                                No. Only the Enterprise On-cloud model will be customizable.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="faq-price-acc-item">
                            <Accordion.Header className="faq-price-acc-header"><h5 style={{fontSize:'17px', fontWeight:'600',}}>Is my data secure on the Smart IG cloud platform?</h5></Accordion.Header>
                            <Accordion.Body className="faq-price-acc-body text-start">
                                <p style={{fontSize:'16px',}}>
                                Yes, your data is 100% secure. We implement robust security measures, such as encryption, regular security audits, and compliance with industry standards, to ensure the safety and confidentiality of your data.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="faq-price-acc-item">
                            <Accordion.Header className="faq-price-acc-header"><h5 style={{fontSize:'17px', fontWeight:'600',}}>User Wants to quit - how Subscription fee is going to be refunded? </h5></Accordion.Header>
                            <Accordion.Body className="faq-price-acc-body text-start">
                                <p style={{fontSize:'16px',}}>
                                Amount fee will be refunded into the Bank account within 3 working days.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5" className="faq-price-acc-item">
                            <Accordion.Header className="faq-price-acc-header"><h5 style={{fontSize:'17px', fontWeight:'600',}}>Will my data be deleted from the cloud after my subscription expires?</h5></Accordion.Header>
                            <Accordion.Body className="faq-price-acc-body text-start">
                                <p style={{fontSize:'16px',}}>
                                Yes, all data will be provided in XLSX files and will be deleted 30 days after the subscription expiration date.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Container>
            </section>



            {/* <section className="mt-5 py-5">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col lg={12}>

                            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}
                                className="price-data-head" style={{ marginBottom: '3rem' }}>
                                <Tab eventKey="yearly" title="Yearly">
                                    <Row>
                                        <Col lg={12}>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header><h5>Data Integration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th style={{ width: '230px' }}></th>
                                                                    <th style={{ width: '230px' }}><h5>BASIC</h5>
                                                                        <p className="ta-he-pri">$49.99</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>PROFESSIONAL</h5>
                                                                        <p className="ta-he-pri">$79.99</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE (Cloud) </h5>
                                                                       
                                                                        <p className="ta-he-pri">$99.99</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE </h5>
                                                                        <h6>(On-Premise)</h6>
                                                                        
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">Files</p>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>XLS</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XLSX</td>
                                                                    <td ><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>CSV</td>
                                                                    <td ><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JSON</td>
                                                                    <td ><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">DataBase</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Oracle</td>
                                                                    <td ><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MS SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MY SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM DB2
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Maria Database
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Ms Access
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Lite
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Server
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Postgre SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Mongo DB
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM netezza
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Teradata
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Azure SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">ERP</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SAP ECC
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SAP HANA
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Oracle ERP
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MS Dynamics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">Online Services</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        X
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Youtube
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Facebook
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        LinkedIn
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><h5>Data Migration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th style={{ width: '230px' }}><h5>BASIC</h5>
                                                                        <p className="ta-he-pri">$49.99</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>PROFESSIONAL</h5>
                                                                        <p className="ta-he-pri">$79.99</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE (Cloud) </h5>
                                                                        
                                                                        <p className="ta-he-pri">$99.99</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE </h5>
                                                                        <h6>(On-Premise)</h6>
                                                                       
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                
                                                                <tr>
                                                                    <td>SAP ECC</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>SAP HANA</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Oracle ERP</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>MS Dynamics</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Oracle</td>
                                                                    <td ><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MS SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MY SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM DB2
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Maria Database
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Ms Access
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Lite
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Server
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Postgre SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Mongo DB
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM netezza
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Teradata
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Azure SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header><h5>Data Analytics </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th style={{ width: '230px' }}><h5>BASIC</h5>
                                                                        <p className="ta-he-pri">$49.99</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>PROFESSIONAL</h5>
                                                                        <p className="ta-he-pri">$79.99</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE (Cloud) </h5>
                                                                        
                                                                        <p className="ta-he-pri">$99.99</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE </h5>
                                                                        <h6>(On-Premise)</h6>
                                                                        
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                
                                                                <tr>
                                                                    <td>Charts</td>
                                                                    <td>16</td>
                                                                    <td>80</td>
                                                                    <td>200</td>
                                                                    <td>200</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dash Boards</td>
                                                                    <td>2</td>
                                                                    <td>10</td>
                                                                    <td>25</td>
                                                                    <td>25</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Data pre-processing</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Auto Suggestion Charts (AI Driven)</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Download Dashboard as PDF</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Refresh Rate
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>Hourly</td>
                                                                    <td>As Nedded</td>
                                                                    <td>As Nedded</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    iMirAI[co-pilot]
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Advanced Data Preparation
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Data Quering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Personalized dashboards
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Fine tune on Built-in Algorithms
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Drill Down and Customize Charts
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Self-service Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    AI Generated Trends
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Vice-Activated Querying
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Smart Data Discovering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Automated Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                    Visualization From Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Natural Language Questions
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Contexual Analysis
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                    AI Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Mail Notification
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Mobile App
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Tab>

                                <Tab eventKey="monthly" title="Monthly">
                                    <Row>
                                        <Col lg={12}>
                                        <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header><h5>Data Integration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th style={{ width: '230px' }}><h5>BASIC</h5>
                                                                        <p className="ta-he-pri">$65.99</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>PROFESSIONAL</h5>
                                                                        <p className="ta-he-pri">$99.99</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE (Cloud)</h5>
                                                                       
                                                                        <p className="ta-he-pri">$119.99</p>
                                                                        <p className="ta-he-pri">10 User (Minium)</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE </h5>
                                                                        <h6>(On-Premise)</h6>
                                                                       
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">Files</p>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td>XLS</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>XLSX</td>
                                                                    <td ><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>CSV</td>
                                                                    <td ><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>JSON</td>
                                                                    <td ><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">DataBase</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Oracle</td>
                                                                    <td ><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MS SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MY SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM DB2
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Maria Database
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Ms Access
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Lite
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Server
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Postgre SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Mongo DB
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM netezza
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Teradata
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Azure SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">ERP</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SAP ECC
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SAP HANA
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Oracle ERP
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MS Dynamics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <p className="price-inner-1 text-start mx-2">Online Services</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        X
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Youtube
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Facebook
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        LinkedIn
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><h5>Data Migration </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th style={{ width: '230px' }}><h5>BASIC</h5>
                                                                        <p className="ta-he-pri">$65.99</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>PROFESSIONAL</h5>
                                                                        <p className="ta-he-pri">$99.99</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE (Cloud) </h5>
                                                                        
                                                                        <p className="ta-he-pri">$119.99</p>
                                                                        <p className="ta-he-pri">10 User (Minium)</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE </h5>
                                                                        <h6>(On-Premise)</h6>
                                                                        
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                
                                                                <tr>
                                                                    <td>SAP ECC</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>SAP HANA</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Oracle ERP</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>MS Dynamics</td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Oracle</td>
                                                                    <td ><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MS SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        MY SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM DB2
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Maria Database
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Ms Access
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Lite
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        SQL Server
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Postgre SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Mongo DB
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        IBM netezza
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Teradata
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Azure SQL
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header><h5>Data Analytics </h5></Accordion.Header>
                                                    <Accordion.Body>
                                                        <Table striped bordered hover size="sm">
                                                            <thead className="ta-hea-2">
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th style={{ width: '230px' }}><h5>BASIC</h5>
                                                                        <p className="ta-he-pri">$65.99</p>
                                                                        <p className="ta-he-pri">1 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>PROFESSIONAL</h5>
                                                                        <p className="ta-he-pri">$99.99</p>
                                                                        <p className="ta-he-pri">3 User</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE (Cloud)</h5>
                                                                        
                                                                        <p className="ta-he-pri">$119.99</p>
                                                                        <p className="ta-he-pri">10 User(Minium)</p>
                                                                    </th>
                                                                    <th style={{ width: '230px' }}>
                                                                        <h5>ENTERPRISE </h5>
                                                                        <h6>(On-Premise)</h6>
                                                                        
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                
                                                                <tr>
                                                                    <td>Charts</td>
                                                                    <td>16</td>
                                                                    <td>80</td>
                                                                    <td>200</td>
                                                                    <td>200</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dash Boards</td>
                                                                    <td>2</td>
                                                                    <td>10</td>
                                                                    <td>25</td>
                                                                    <td>25</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Data pre-processing</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Auto Suggestion Charts (AI Driven)</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Download Dashboard as PDF</td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Refresh Rate
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td>Hourly</td>
                                                                    <td>As Nedded</td>
                                                                    <td>As Nedded</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    iMirAI[co-pilot]
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Advanced Data Preparation
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Data Quering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Personalized dashboards
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Fine tune on Built-in Algorithms
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Drill Down and Customize Charts
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Self-service Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    AI Generated Trends
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Vice-Activated Querying
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Smart Data Discovering
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Automated Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                    Visualization From Insights
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Natural Language Questions
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Contexual Analysis
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                    AI Predictive Analytics
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Mail Notification
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    Mobile App
                                                                    </td>
                                                                    <td><CloseIcon className="close-ico-price" /></td>
                                                                    <td><CloseIcon className="close-ico-price"/></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                    <td><CheckIcon className="check-ico-price" /></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>


                </Container>
            </section> */}
        </div>
    )
}