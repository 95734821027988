import React from 'react'
import './dataanalytics.css'
import { Accordion, Col, Container, Row } from 'react-bootstrap'

export const DataAnalytics2 = () => {
  return (
    <div>
         <section style={{ marginTop: '5rem' }} className='py-3'>
                <Container>
                    <div className="data-analytics-outer">
                        <div className="data-main-analytics-inner">
                            <Row className='align-items-center justify-content-center'>
                                <Col lg={10}>
                                    <h5 className='head-fot-di'>
                                        Discover the Power of Data Analytics
                                    </h5>
                                    <p>
                                        Smart Integraphics application offers a wide range of connectors for various data sources. These include file formats such as Excel, CSV, TXT, JSON, and XML. For databases, it supports SQL, Oracle, MySQL, MongoDB, and PostgreSQL. Additionally, it integrates seamlessly with popular ERP systems like SAP, Oracle, Microsoft Dynamics, S/4HANA, and IFS. With such a diverse set of integration options, Smart Integraphics provides users with extensive flexibility and versatility for their data analytics needs
                                    </p>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'  >
                        <Col lg={6} className=' px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Customizable Dashboards
                                    </h5>
                                    <p >
                                        The Smart Integraphics tool allows users to create personalized dashboards by selecting relevant charts, graphs, and data points. Its intuitive drag-and-drop functionality makes customization easy, without any technical expertise or coding knowledge. By integrating with various data sources, it provides real-time insights and enhances strategic decision-making. Smart Integraphics transforms complex data into clear, actionable visualizations, boosting productivity, collaboration, and enabling data-driven decisions for better outcomes.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/Chart&dashboard-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Chart&dashboard-2_1.gif')} className='w-100' alt='interactive-dashboard' />
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                    <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/Chart&dashboard-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                           <img src={require('../assets/data-analytics/Compress/AI-Insights-2.gif')} className='w-100' alt='interactive-dashboard' />
                        </Col>

                        <Col lg={6}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3 ">
                                    <h5 className='head-fot-di' >
                                        Auto-Suggested Charts
                                    </h5>
                                    <p >
                                        Auto-suggested charts in BI tools automatically recommend the most relevant visualizations based on the data selected by the user. These suggestions leverage algorithms to identify the best chart types (e.g., bar, line, pie) that effectively communicate the underlying trends and patterns. This feature streamlines the data analysis process, saving users time and ensuring that insights are presented in the most impactful way. By offering smart recommendations, auto-suggested charts enhance data exploration and decision-making efficiency
                                    </p>


                                </div>
                            </div>
                        </Col>

                       
                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>

                        <Col lg={6} className='px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Uncover insights with AI
                                    </h5>
                                    <p >
                                        AI insights on data use machine learning and advanced algorithms to analyze large datasets, uncover hidden patterns, and predict future trends. These insights help businesses identify new opportunities, optimize operations, and make data-driven decisions. AI can automate data analysis, reducing manual effort and improving efficiency. It also provides actionable recommendations, enabling proactive decision-making. Overall, AI insights enhance business intelligence by delivering deeper, more accurate insights faster.
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/AI-Insights-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/AI-Insights-2.gif')} className='w-100' alt='interactive-dashboard'  />
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                    <Col lg={6}  >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Mail-DB-2.gif')} className='w-100' alt='interactive-dashboard' />
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Predictive Analytics
                                    </h5>
                                    <p >
                                        Predictive analytics uses historical data, statistical algorithms, and machine learning techniques to identify the likelihood of future outcomes based on patterns in past data. This approach helps organizations forecast trends, behaviors, and potential events, allowing them to make data-driven predictions, optimize strategies, improve risk management, and informed decisions that enhance operational efficiency, profitability, and competitive advantage. By leveraging advanced modeling techniques, predictive analytics also helps identify emerging opportunities, streamline processes, and foster innovation across various industries.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        

                    </Row>
                </Container>
            </section>

            <section className='mt-4'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={6} className='px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Data Drill-Down
                                    </h5>
                                    <p>
                                        Users can explore data at different levels of granularity by drilling down into more detailed information. This allows them to navigate from high-level summary data to specific data points, uncovering underlying trends, patterns, and actionable insights. By drilling down, users can gain a deeper understanding of individual elements, such as sales performance by region or product, helping to identify root causes, inefficiencies, or opportunities for improvement and strategic decision-making.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/Mail-DB-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Chart&dashboard-2.gif')} className='w-100' alt='interactive-dashboard' />
                        </Col>


                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                    <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Mail_DB.gif')} className='w-100' alt='interactive-dashboard' />
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Sharing and Granting Access to Dashboards and Reports
                                    </h5>
                                    <p>
                                        Sharing and granting access to dashboards and reports allows users to securely distribute insights to stakeholders and team members based on their roles. Smart Integraphics provide features to control access permissions, such as view-only or edit access, ensuring that sensitive data is protected. Users can share reports via email or embedded within other platforms, promoting collaboration and efficient decision-making. This feature empowers teams to stay aligned with real-time data while maintaining data security and privacy through role-based access controls.
                                    </p>
                                </div>
                            </div>
                        </Col>

                        

                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={6} className='px-4'>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new mt-2"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>
                                        Natural Language Processing (NLP)
                                    </h5>
                                    <p>
                                        Natural Language Processing (NLP) in Smart Integraphics allows users to interact with data using natural language, such as asking questions like "What were the sales last month?" or "Show me the top-performing products." It simplifies data querying for non-technical users, eliminating the need for complex coding or SQL knowledge. NLP enhances data accessibility, enabling anyone in the organization to quickly derive insights. This feature speeds up decision-making by providing instant, actionable answers in the form of reports or visualizations
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/NLP.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            {/* <img src={require('../assets/data-analytics/NLP.')} */}
                            <img src={require('../assets/data-analytics/Compress/NLP.gif')} className='w-100' alt='interactive-dashboard' />
                        </Col>



                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                    <Col lg={6}  >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid #372599' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video> */}
                            <img src={require('../assets/data-analytics/Compress/Mail_DB.gif')} className='w-100' alt='interactive-dashboard' />
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-start, justify-content-center text-start ">
                                <div className="data-analaytics-slide-new"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di' >
                                        Data Alerts and Notifications
                                    </h5>
                                    <p>
                                        Data alerts and notifications in Smart Integraphics automatically inform users when specific conditions or thresholds are met, such as when sales reach a target or inventory drops below a set level. This feature enables proactive decision-making by alerting users to critical changes in real time. It enhances efficiency by reducing manual monitoring and ensuring key events are never missed. Automated notifications help users stay on track with business goals by delivering timely insights. Overall, data alerts empower quicker, data-driven actions for improved business performance
                                    </p>
                                </div>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </section>

    </div>
  )
}
