import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './home.css'
import { Link } from 'react-router-dom'

export const HomePage2 = () => {
  return (
    <div>


    <section className='new-home-page-tool-design py-2' style={{ marginTop: '4rem' }}>
        <Container>
            <Row className='text-start align-items-center justify-content-center'>
                <Col lg={6} >
                    <div className='head-of-new-toll '>

                        <h1 className='head-fot-di h4'>
                            Welcome to <br/> Smart Integraphics
                        </h1>
                        <p>
                            Smart Integraphics is an AI-powered Business Intelligence tool that revolutionizes data analytics, integration, and migration. Leveraging advanced artificial intelligence, it provides powerful insights to help you make data-driven decisions with ease.  Smart Integraphics simplifies complex processes, making it an essential solution for businesses looking to optimize data management and accelerate growth
                        </p>
                        <Link to='https://calendly.com/customerdemos/smartig_mirai?month=2024-12' target='blank'>
                            <Button className='btn-new-ho mt-2'>Book a Demo</Button>
                        </Link>

                    </div>
                </Col>

                <Col lg={6}>
                    <img src={require('../assets/home/SmartIG-Banner-new-increase.gif')} className='w-100' alt="gif" />
                    {/* <img src="https://smartintegraphics.com/assets/home/SmartIG-Banner-new-increase.gif" className='w-100' alt="gif" /> */}
                </Col>
            </Row>

            {/* <div className="d-flex align-items-center justify-content-center">
                <div className="" style={{width:'100%'}}>
                    <div className='head-of-new-toll text-start '>
                       
                        <h1>
                            Welcome to Smart Integraphics
                        </h1>
                        <p>
                            Smart Integraphics is an AI-powered Business Intelligence tool that revolutionizes data analytics, integration, and migration. Leveraging advanced artificial intelligence, it provides powerful insights to help you make data-driven decisions with ease. Seamlessly integrate and migrate data across platforms, while gaining in-depth analytics for improved business performance. Smart Integraphics simplifies complex processes, making it an essential solution for businesses looking to optimize data management and accelerate growth
                        </p>
                        <Link to='https://calendly.com/customerdemos/smartig_mirai?month=2024-12' target='blank'>
                            <Button className='btn-new-ho mt-2'>Book a Demo</Button>
                        </Link>
                       
                    </div>
                </div>
                <div className="">

                <img src={require('../assets/home/GIF.gif')} className='w-100' alt="gif" />

                </div>
            </div> */}
        </Container>

    </section>

    <section className='my-3'>
        <Container>
            <div className="cal-to-new-outer">
                <div className="cal-inner">
                    <Row style={{ transform: 'rotate(0deg)' }} className='align-items-center justify-content-center '>
                        <Col lg={10}>
                            <h5 className='head-fot-di'>
                                Discover the Power of Our Product
                            </h5>
                            <p>
                                Unlock the full potential of your data with our innovative solution. Our product combines cutting-edge AI, seamless data integration, and advanced analytics to deliver actionable insights. Experience enhanced efficiency, smarter decision-making, and effortless data migration all in one platform. Take your business to the next level with a tool designed to drive growth and transform how you manage and utilize data
                            </p>
                        </Col>
                    </Row>

                </div>
            </div>
        </Container>
    </section>

    {/* features start */}

    <section className='mt-5'>
        <Container>
            <Row className='align-items-center justify-content-center'>


                <Col lg={6}>
                    <div className="d-flex align-items-start justify-content-center pt-4">
                        <div className="home-slid-bar-new"></div>
                        <div className="">
                            <div className="text-start mx-4">
                                <h5 className='head-fot-di' style={{ fontWeight: '600' }}>Data Integration</h5>
                                <p className='mt-4'>
                                    Data integration combines data from various sources and systems into a unified, accessible view. It involves techniques like data cleaning, transformation, and loading  to ensure accuracy and consistency. By consolidating data, organizations improve decision-making, streamline workflows, and enhance business intelligence. This reduces redundancy, provides real-time insights, and offers a comprehensive understanding of operations. Data integration helps businesses optimize processes, enhance collaboration, and maintain a competitive edge in a data-driven environment, enabling more informed and efficient decision-making.
                                </p>
                                <Link to='data-integration'>
                                    <Button className='btn-new-ho'>Read More</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6}>
                    <img src={require('../assets/home/Side-Images-integration-home.png')} style={{ borderRadius: '10px' }} alt='hone-1' className='w-75 mt-3' />
                    {/* <img src="https://smartintegraphics.com/assets/home/Side-Images-integration-home.png" style={{ borderRadius: '10px' }} alt='hone-1' className='w-75 mt-3' /> */}
                </Col>
            </Row>
        </Container>
    </section>

    <section className='mt-5'>
        <Container>
            <Row className='align-items-center justify-content-center'>
            <Col lg={6}>
                    <img src={require('../assets/home/data-migration2-2.jpg')} style={{ borderRadius: '10px', border: '1px solid #372599' }} alt='hone-1' className='w-75' />
                    {/* <img src='https://smartintegraphics.com/assets/home/data-migration2-2.jpg' style={{ borderRadius: '10px', border: '1px solid #372599' }} alt='hone-1' className='w-75' /> */}
                </Col>
                <Col lg={6}>
                    <div className="d-flex align-items-start justify-content-center">
                        <div className="home-slid-bar-new"></div>
                        <div className="">
                            <div className="text-start mx-4">
                                <h5 className='head-fot-di' style={{ fontWeight: '600' }}>Data Migration</h5>
                                <p className='mt-4'>
                                    Data migration is the process of transferring data between systems, storage, or applications, often during upgrades or technology transitions. It involves planning, mapping, extracting, transforming, and loading  data to ensure accuracy and minimal disruption. Proper migration ensures data is accessible, usable, and secure in the new environment, helping organizations maintain continuity, optimize performance, and improve scalability. Well-executed data migration reduces risks of data loss, enhances operational efficiency, and supports business growth by enabling smoother transitions and better data management.
                                </p>
                                <Link to='data-migration'>
                                    <Button className='btn-new-ho'>Read More</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>

                
            </Row>
        </Container>
    </section>

    <section className='mt-5'>
        <Container>
            <Row className='align-items-center justify-content-center'>
                <Col lg={6}>
                    <div className="d-flex align-items-start justify-content-center">
                        <div className="home-slid-bar-new"></div>
                        <div className="">
                            <div className="text-start mx-4">
                                <h5 className='head-fot-di' style={{ fontWeight: '600' }}>Data Analytics</h5>
                                <p className='mt-4'>
                                    Data analytics is the process of examining and interpreting data to uncover valuable insights, trends, and patterns. It involves various techniques such as statistical analysis, data mining, and machine learning to extract actionable information from raw data. Organizations use data analytics to make informed decisions, optimize performance, and improve strategies. It plays a crucial role in areas like marketing, finance, healthcare, and operations, helping businesses enhance efficiency, predict outcomes, and gain a competitive advantage in the market.
                                </p>
                                <Link to='data-analytics'>
                                    <Button className='btn-new-ho'>Read More</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6}>
                    <img src={require('../assets/home/IG-Images-new-2.jpg')} className='w-75' style={{ borderRadius: '10px' }} alt='hone-1' />
                    {/* <img src='https://smartintegraphics.com/assets/home/IG-Images-new-2.jpg' className='w-75' style={{ borderRadius: '10px' }} alt='hone-1' /> */}
                </Col>

            </Row>
        </Container>
    </section>

    {/* features end */}

    <section className='py-4 mt-3'>
        <Container>
            <Row>
                <Col lg={6} className='text-start'>
                    <h5 className='head-fot-di' style={{ fontWeight: '600' }}>
                        Discover the Power of iMirAI
                    </h5>
                    <p>
                        iMirAI uses Natural Language Processing (NLP) to interpret and analyze data through conversational queries. This enables users to interact with iMirAI in plain language, making complex data insights easily accessible                            </p>
                    {/* <Button className='btn-new-ho'>Contact Us</Button> */}
                    <img src={require('../assets/imirAI/IMirai_Video.gif')} className='w-100' alt='IMirai' />
                    {/* <img src='https://smartintegraphics.com/assets/imirAI/IMirai_Video.gif' className='w-100' alt='IMirai' /> */}

                    {/* <video className='w-100' controls style={{ borderRadius: '10px' }}>
                        <source
                            src={require('../assets/home/NLP.mp4')}
                            type="video/mp4"
                        />
                    </video> */}
                </Col>
                {/* <col lg={1}></col> */}
                <Col lg={6} className=' align-items-center justify-content-center d-flex' style={{ flexDirection: 'column' }}>
                    <div className="new-ro-fra-1 p-1 ">
                        <h3 className='py-2'>
                            Natural language Questions
                        </h3>
                        <p className='text-center'>
                            Natural language questions in a Smart IG allow users to query data by simply typing in conversational language, making data analysis more intuitive
                        </p>
                    </div>
                    <div className="new-ro-fra-2 p-1 mt-4 ">
                        <h3 className='py-2'>
                            AI-powered suggestion of questions
                        </h3>
                        <p className='text-center'>
                            AI-powered suggestion of questions in a Smart IG helps users generate relevant queries based on the available data.
                        </p>
                    </div>

                    <div className="new-ro-fra-1 p-1 mt-4 ">
                        <h3 className='py-2'>
                            AI-powered insights
                        </h3>
                        <p className='text-center'>
                            AI-powered insights in a Smart IG analyze data to automatically detect patterns, trends, and anomalies.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>

    {/* socail media */}

    <section className='mt-5'>
        <Container>
            <div className="cal-to-new-outer">
                <div className="cal-inner">
                    <Row className='align-items-center justify-content-center '>
                        <Col lg={10}>
                            <h4 className='head-fot-di'>
                                Articles
                            </h4>
                            <p>
                                Explore our curated collection of insightful articles, expert-driven LinkedIn posts, and engaging YouTube videos. Stay up-to-date with the latest trends and innovations in data analytics. Dive into industry strategies that can transform your approach. Keep ahead of the curve with valuable resources tailored to enhance your knowledge and expertise
                            </p>
                        </Col>
                    </Row>

                </div>
            </div>
        </Container>
    </section>

    <section className='py-3'>
        <Container>
            <Row>
                <Col lg={6}>
                    <div className="d-flex align-items-start justify-content-center">
                        <div className="home-slid-bar-new"></div>
                        <div className="">
                            <div className="text-start mx-4">
                                <h5 className='head-fot-di' style={{ fontWeight: '600' }}>Media Articles</h5>
                                <p className='mt-4'>
                                    Explore our curated media articles featuring valuable industry insights, expert perspectives, and groundbreaking solutions in data analytics. Stay informed and ahead of the curve with our latest content, designed to provide you with in-depth knowledge, emerging trends, and innovative strategies that drive the future of data-driven decision-making.                                        </p>

                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6}>
                    <img src={require('../assets/home/LinkedinPost-Image.jpg')} style={{ borderRadius: '10px' }} alt='hone-1' className='w-75' />
                    {/* <img src='https://smartintegraphics.com/assets/home/LinkedinPost-Image.jpg' style={{ borderRadius: '10px' }} alt='hone-1' className='w-75' /> */}
                </Col>
            </Row>
        </Container>
    </section>

    <section className='pb-3'>
        <Container>
            <Row>
                <Col lg={6}>
                    <img src={require('../assets/home/YouTube-Image-home.jpg')} style={{ borderRadius: '10px' }} alt='hone-1' className='w-75' />
                    {/* <img src='https://smartintegraphics.com/assets/home/YouTube-Image-home.jpg' style={{ borderRadius: '10px' }} alt='hone-1' className='w-75' /> */}
                </Col>
                <Col lg={6}>
                    <div className="d-flex align-items-start justify-content-center">
                        <div className="home-slid-bar-new"></div>
                        <div className="">
                            <div className="text-start mx-4">
                                <h5 className='head-fot-di' style={{ fontWeight: '600' }}>Linkedin Post</h5>
                                <p className=''>
                                    Follow our LinkedIn page for the latest updates, thought leadership, and discussions on data analytics trends and best practices.
                                </p>
                                <h5 className='head-fot-di' style={{ fontWeight: '600' }}>Youtube Post</h5>
                                <p className=''>
                                    Check out our YouTube channel for informative content, feature upgrades, tutorials, and expert interviews that explore the latest in data analytics.
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>



    {/* <section className='py-3'>
        <Container>
            <Row>

                <Col lg={6}>
                    <div className="d-flex align-items-start justify-content-center">
                        <div className="home-slid-bar-new"></div>
                        <div className="">
                            <div className="text-start mx-4">
                                <h4 style={{ fontWeight: '600' }}>Youtube Post</h4>
                                <p className='mt-4'>
                                    Check out our YouTube channel for informative content, feature upgrades, tutorials, and expert interviews that explore the latest in data analytics.
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col lg={6}>
                    <img src={require('../assets/home/YouTube-Image-home.jpg')} style={{ borderRadius: '10px' }} alt='hone-1' className='w-75' />
                </Col>
            </Row>
        </Container>
    </section> */}

</div>
  )
}
