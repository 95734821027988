// import logo from './logo.svg';
import './App.css';
// import { Navbar } from './Components/navbar components/Navbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './Components/home component/Home';
import { Header } from './Components/header component/Header';
import { Dataintegration } from './Components/data-integration component/Dataintegration';
import { DataAnalytics } from './Components/data-analytics Component/DataAnalytics';
import { Footer } from './Components/footer component/Footer';
import { DataMigration } from './Components/data-migration component/DataMigration';
import { ContactUs } from './Components/contactus component/Contactus';
import { Pricing } from './Components/pricing Component/Pricing';
import { Testing } from './Components/Test component/Testing';
import { IMirAI } from './Components/imir component/ImirAI';
import { Aboutus } from './Components/aboutus component/Aboutus';
import { useEffect } from 'react';
import { Privacy } from './Components/Privacypolice component/Privace';
import { Integration } from './Components/data-integration component/Integration';
import { DataAnalytics2 } from './Components/data-analytics Component/DataAnalytics2';
import { HomePage2 } from './Components/home component/HomePage2';
import { Migration2 } from './Components/data-migration component/Migration2';


function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <header>
        <Header />
      </header>
      <ScrollToTopOnNavigate/>
      <main>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='home-2' element={<HomePage2 />} />
          <Route path='data-analytics' element={<DataAnalytics/>}/>
          <Route path='data-analytics-2' element={<DataAnalytics2/>}/>
          <Route path='data-integration' element={<Dataintegration />} />
          <Route path='data-migration' element={<DataMigration />} />
          <Route path='data-migration-2' element={<Migration2/>} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='pricing' element={<Pricing />} />
          <Route path='imirai' element={<IMirAI/>} />
          <Route path='About-Us' element={<Aboutus/>} />
          <Route path='test' element={<Testing/>} />
          <Route path='privacy' element={<Privacy/>} />
          <Route path='integration' element={<Integration/>} />

        </Routes>
      </main>
      <footer>
        <Footer/>
      </footer>
    </div>
  );
}

export default App;
