import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './imirai.css'




export const IMirAI = () => {




    return (
        <>
 <section style={{ marginTop: '5rem' }} className='py-1'>
        <Container>
          <div className="data-integration-outer ">
            <div className="imirai-main-inner">
              <Row className='align-items-center justify-content-center'>
                <Col lg={10}>
                  <h5 className='head-fot-di'>
                  Discover the Power of iMirAI
                  </h5>
                  <p className="m-0">
                  iMirAI uses Natural Language Processing (NLP) to interpret and analyze data through conversational queries. This enables users to interact with iMirAI in plain language, making complex data insights easily accessible.
                  </p>
                </Col>
              </Row>

            </div>
          </div>
        </Container>
      </section>

            <section >
                <Container>
                    <Row className="align-items-center justify-content-center mt-2">
                        <Col lg={8}>
                            {/* <video  controls style={{ borderRadius: '8px', height:'90%', width: '100%' }}>
                                <source
                                    src={require('../assets/imirAI/IMirai_Video.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/imirAI/IMirai_Video.gif')} className='w-100' alt='IMirai' />

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-4">
                <Container>
                    <Row className="text-start">
                        <h4 className="py-3">iMirAI  Features</h4>
                        <Col lg={4}>
                            <div className="imir-car mt-3 mt-2">
                            

                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Al-Powered Insights-01.png" )} style={{width:'15%', gap:'1rem'}} alt="Al-Powered Insights" />
                                    <h5>
                                    AI-Powered Insights
                                </h5>
                                </div>
                                
                                <p>
                                    Automatically generate actionable insights from your data, identifying trends, anomalies, and key patterns.
                                </p>
                            </div>

                        </Col>
                        <Col lg={4}>
                            <div className="imir-car mt-3 mt-2">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Natural Language Processing (NLP) Questions & Answers2-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Natural Language Processing" />
                                    <h5>
                                    Natural Language Processing (NLP) Questions & Answers
                                </h5>
                                </div>
                               
                                <p>
                                    Ask data-related questions in natural language and get instant, accurate answers. Simplify data analysis by communicating with iMirAI like a human.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3 mt-2">
                            
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Al-Powered Auto-Suggested Questions-01.png" )} style={{width:'15%', gap:'1rem'}} alt="Al-Powered Auto-Suggested" />
                                    <h5 className=''>
                                    AI-Powered Auto-Suggested Questions
                                </h5>
                                </div>
                                
                                <p>
                                    iMirAI suggests relevant questions based on your dataset, helping you explore various aspects of the data without needing to know specific queries in advance.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                           
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Correlation Analysis-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Correlation Analysis-01" />
                                    <h5>
                                    Correlation Analysis
                                </h5>
                                </div>
                               
                                <p>
                                    Identify and visualize relationships between columns or variables in your dataset. Detect correlations that can reveal valuable patterns.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Outliers Detection-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Outliers Detection" />
                                    <h5>
                                    Outliers Detection
                                </h5>
                                </div>
                               
                                <p>
                                    iMirAI detects outliers in your datasets, highlighting unusual or extreme values that may need special attention or further investigation
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Cross-Column Queries-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Cross-Column Queries" />
                                    <h5>
                                    Cross-Column Queries
                                </h5>
                                </div>
                               
                                <p>
                                    Pose complex queries that combine multiple columns for advanced analysis, such as asking questions that consider multiple variables at once.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Querying Data Values-01.png" )} style={{width:'15%', gap:'1rem'}} alt="Querying Data Values" />
                                    <h5>
                                    Querying Data Values
                                </h5>
                                </div>
                               
                                <p>
                                    Ask specific questions about individual data points or values, enabling you to drill deeper into the dataset for more granular insights.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Multi-Dataset Compatibility-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Multi-Dataset Compatibility1" />
                                    <h5>
                                    Multi-Dataset Compatibility
                                </h5>
                                </div>
                                
                                <p>
                                    iMirAI can work with multiple datasets simultaneously, allowing comparisons and insights across different data sources and structures.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Unlimited Dataset Size-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Unlimited Dataset Size" />
                                    <h5>
                                    Unlimited Dataset Size
                                </h5>
                                </div>
                                
                                <p>
                                    No limitations on dataset size. iMirAI is designed to handle datasets of any scale, from small samples to big data, without performance degradation.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Predictive Analytics-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Predictive Analytics" />
                                    <h5>
                                    Predictive Analytics
                                </h5>
                                </div>
                               
                                <p>
                                    Leverage machine learning algorithms to forecast future trends and outcomes based on historical data, helping you make proactive decisions.
                                </p>
                            </div>

                        </Col>

                        {/* <Col lg={4}>
                            <div className="imir-car mt-3">
                                <h5>
                                    Automated Data Cleaning
                                </h5>
                                <p>
                                    iMirAI automatically detects and suggests corrections for data inconsistencies, missing values, or outliers, ensuring your data is always ready for analysis.
                                </p>
                            </div>

                        </Col> */}

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                                <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Summary on Chat History-01.png" )} style={{width:'15%', gap:'1rem'}} alt="Summary on Chat History" />
                                    <h5>
                                    Summary on Chat History
                                </h5>
                                </div>

                                <p>
                                    iMirAI provides a summary of previous interactions, helping you quickly refer back to past conversations and insights without needing to retrace steps.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>
                            <div className="imir-car mt-3">
                            <div className="d-flex gap-3 align-items-center" >
                                    <img  src={require("../assets/imirAI/Voice Enabled-01.png" )} style={{width:'15%', gap:'1rem'}} alt=" Voice Enabled" />
                                    <h5>
                                    Voice Enabled
                                </h5>
                                </div>
                                
                                <p>
                                    Interact with iMirAI using voice commands. Speak naturally to ask questions or request insights, making it easier to use in hands-free or mobile environments.
                                </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section style={{ marginTop: '6rem' }}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <video controls autoPlay muted style={{ borderRadius: '10px' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video>
                        </Col>

                        <Col lg={6} className="text-start">
                            <h5>
                                Natural language Questions
                            </h5>
                            <p>
                                Natural language questions in a Smart IG allow users to query data by simply typing in conversational language, making data analysis more intuitive
                            </p>
                            <h5>
                                AI-powered suggestion of questions
                            </h5>
                            <p>
                                AI-powered suggestion of questions in a Smart IG helps users generate relevant queries based on the available data.
                            </p>
                            <h5>
                                AI-powered insights
                            </h5>
                            <p>
                                AI-powered insights in a Smart IG analyze data to automatically detect patterns, trends, and anomalies.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-3">
                <Container>
                    <Row>
                        <Col lg={6} className="text-start">
                            <h5>
                                Natural language Questions
                            </h5>
                            <p>
                                Natural language questions in a Smart IG allow users to query data by simply typing in conversational language, making data analysis more intuitive
                            </p>
                            <h5>
                                AI-powered suggestion of questions
                            </h5>
                            <p>
                                AI-powered suggestion of questions in a Smart IG helps users generate relevant queries based on the available data.
                            </p>
                            <h5>
                                AI-powered insights
                            </h5>
                            <p>
                                AI-powered insights in a Smart IG analyze data to automatically detect patterns, trends, and anomalies.
                            </p>
                        </Col>

                        <Col lg={6}>
                            <video controls autoPlay muted style={{ borderRadius: '10px' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-analytics/data-analytics-video.webm')}
                                    type="video/webm"
                                />
                            </video>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* <section>
                <Container>
                    <Row>
                        <Carousel slide={true}>
                            <Carousel.Item>
                                
                                <img src={require('../assets/imirAI/Screenshot1.png')} className="w-25" alt="imirai1"/>

                                <Carousel.Caption>
                                    <h3>First slide label</h3>
                                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                
                                <img src={require('../assets/imirAI/Screenshot1.png')} className="w-25" alt="imirai1"/>
                                <Carousel.Caption>
                                    <h3>Second slide label</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                
                                <img src={require('../assets/imirAI/Screenshot1.png')} className="w-25" alt="imirai1"/>

                                <Carousel.Caption>
                                    <h3>Third slide label</h3>
                                    <p>
                                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                    </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Row>
                </Container>
            </section> */}
            {/* <div className="custom-carousel-container" style={{ marginTop: '6rem' }}>
                <Carousel className="custom-carousel">
                    <Carousel.Item className="custom-carousel-item">
                        <div className="custom-carousel-content">
                            <img src={require('../assets/imirAI/Screenshot1.png')} className="custom-carousel-image w-25" alt="tool-ima" />
                            <div className="custom-carousel-text">
                                <h2>Title 1</h2>
                                <p>Description for item 1 goes here.</p>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item className="custom-carousel-item">
                        <div className="custom-carousel-content">
                            <img src={require('../assets/imirAI/Screenshot1.png')} className="custom-carousel-image w-25" alt="tool-ima" />                            <div className="custom-carousel-text">
                                <h2>Title 2</h2>
                                <p>Description for item 2 goes here.</p>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item className="custom-carousel-item">
                        <div className="custom-carousel-content">
                            <img src={require('../assets/imirAI/Screenshot1.png')} className="custom-carousel-image w-25" alt="tool-ima" />                            <div className="custom-carousel-text">
                                <h2>Title 3</h2>
                                <p>Description for item 3 goes here.</p>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item className="custom-carousel-item">
                        <div className="custom-carousel-content">
                            <img src={require('../assets/imirAI/Screenshot1.png')} className="custom-carousel-image w-25" alt="tool-ima" />                            <div className="custom-carousel-text">
                                <h2>Title 4</h2>
                                <p>Description for item 4 goes here.</p>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div> */}


            {/* <section style={{ marginTop: '6rem' }}>
                <Container>
                    <Carousel>
                        <Carousel.Item interval={1000}>
                            <Row className="align-items-center justify-content-center">
                                <Col lg={5}>
                                    <img src={require('../assets/imirAI/Screenshot1.png')} className="imirai-car-img" alt="tool-1" />
                                </Col>
                                <Col lg={5} className=" text-start">
                                    <h3>Natural language Questions</h3>
                                    <p>
                                        Natural language questions in a Smart Interactive Graph (Smart IG) empower users to query and analyze data using conversational language, removing the need for complex query syntax or technical expertise. This feature makes data analysis more intuitive and accessible, enabling users to interact with their data in a way that feels natural and effortless. By simply typing a question—whether it's about trends, comparisons, or specific insights—users can instantly retrieve relevant information without having to learn specialized tools or programming languages. 
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>

                        <Carousel.Item >
                            <Row className="align-items-center justify-content-center">
                                <Col lg={5}>
                                    <img src={require('../assets/imirAI/Screenshot1.png')} className="imirai-car-img" alt="tool-1" />
                                </Col>
                                <Col lg={5} className=" text-start">
                                    <h3>AI-powered suggestion of questions</h3>
                                    <p>
                                        AI-powered question suggestions in a Smart Interactive Graph (Smart IG) enhance user experience by intelligently generating relevant queries based on the available data. This advanced feature analyzes the dataset to provide context-driven, personalized questions, helping users uncover insights they may not have thought of. By offering tailored suggestions, it simplifies the data analysis process, making it quicker, more efficient, and user-friendly. 
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Row className="align-items-center justify-content-center">
                                <Col lg={5}>
                                    <img src={require('../assets/imirAI/Screenshot1.png')} className="imirai-car-img" alt="tool-1" />
                                </Col>
                                <Col lg={5} className=" text-start">
                                    <h3>AI-powered insights</h3>
                                    <p>
                                    AI-powered insights in a Smart Interactive Graph (Smart IG) leverage advanced algorithms to automatically analyze data, identifying patterns, trends, and anomalies with remarkable precision. This intelligent feature goes beyond traditional data analysis by continuously scanning datasets for hidden correlations and outliers, offering users deeper, more actionable insights. By automating the detection process, it enables faster decision-making and empowers users to stay ahead of emerging trends and potential issues. This feature not only enhances the accuracy of analysis but also saves time, allowing users to focus on strategic decisions while the system uncovers valuable insights.
                                    </p>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </section> */}

        </>
    )
}