import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './datamigration.css'

export const DataMigration = () => {
    return (
        <div className='mt-5'>

            <section style={{ marginTop: '5rem' }} className='py-3'>
                <Container>
                    <div className="data-analytics-outer">
                        <div className="data-main-analytics-inner">
                            <Row className='align-items-center justify-content-center'>
                                <Col lg={10}>
                                    <h5 className='head-fot-di'>
                                        Discover the Power of Data Migration
                                    </h5>
                                    <p>
                                        Data migration enables organizations to upgrade their systems or
                                        infrastructure, ensuring access to improved technology and performance.
                                        It enhances data quality by cleaning and transforming data during the
                                        transfer process
                                    </p>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Container>
            </section>

            <section className='py-3'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12} className=' d-flex align-items-center justify-content-center'>
                            <div className="d-flex align-items-start justify-content-center">
                                <div className="data-migration-slide mt-2"></div>
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        Data Extraction
                                    </h5>
                                    <p>
                                        Data extraction involves pulling information from various source systems using methods like SQL queries, APIs, or file imports. The extracted data is loaded into a staging area, temporarily stored for validation, cleansing, and processing before being moved to its final destination, such as a data warehouse or analytics platform. This approach ensures data integrity, error detection, consistency, and efficient workflows, enabling better data management, decision-making, and business insights. The staging area also provides a controlled environment for addressing data issues before final storage.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className='mt-4' >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid rgb(55, 37, 153)' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-migration/DATA-EXTRACTION-1.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-migration/Data Extraction.gif')} className='w-100' alt='data-extraction' />
                            {/* <img src='https://smartintegraphics.com/assets/data-migration/Data Extraction.gif' className='w-100' alt='data-extraction' /> */}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>

                        <Col lg={12} className='px-4'>
                            <div className="d-flex align-items-start justify-content-center">
                                <div className="data-migration-slide mt-2"></div>
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        Data Validation
                                    </h5>
                                    <p>
                                        Quality checks ensure data accuracy, completeness, and consistency through methods like type validation, null checks, and consistency verification. Sample testing reviews a subset of data to confirm its quality and correctness. Automating validation speeds up the process, especially for large datasets, improving efficiency, reducing manual effort, and enhancing reliability. It ensures consistent results, minimizes human error, and enables real-time processing, making it easier to maintain high-quality, trustworthy data across systems and applications, ultimately supporting better decision-making and operational effectiveness.
                                    </p>


                                </div>
                            </div>
                        </Col>

                        <Col lg={6} className=' d-flex align-items-center justify-content-center'  >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid rgb(55, 37, 153)' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-migration/Data-Validation-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-migration/Data Validation.gif')} className='w-100' alt='data-validation' />
                            {/* <img src='https://smartintegraphics.com/assets/data-migration/Data Validation.gif' className='w-100' alt='data-validation' /> */}
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12}>
                            <div className="d-flex align-items-start justify-content-center">
                                <div className="data-migration-slide mt-2"></div>
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        Data Transformation
                                    </h5>
                                    <p>
                                        Data mapping involves extracting information from source systems using methods like SQL queries, APIs, or file imports. The extracted data is loaded into a staging area for cleansing and validation, ensuring accuracy and consistency. Afterward, the data is enriched by adding missing information or deriving new values, enhancing its quality and relevance for analysis. This process ensures high-quality, reliable, and actionable data for informed decision-making, strategic planning, and effective business insights. The result is comprehensive data that supports better performance and drives business success.
                                    </p>

                                </div>
                            </div>
                        </Col>

                        <Col lg={6} className=' d-flex align-items-center justify-content-center'>
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid rgb(55, 37, 153)' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-migration/DATA_TRANSFORMATION.gif')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-migration/DATA_TRANSFORMATION.gif')} className='w-100' alt='data-extraction' />
                            {/* <img src='https://smartintegraphics.com/assets/data-migration/DATA_TRANSFORMATION.gif' className='w-100' alt='data-extraction' /> */}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>

                        <Col lg={12}>
                            <div className="d-flex align-items-start justify-content-center">
                                <div className="data-migration-slide mt-2"></div>
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        Data consolidation and merging
                                    </h5>

                                    <p>
                                        Consolidating sources involves merging data from multiple systems into a unified dataset, ensuring consistency across all information. Once consolidated, the validated and transformed data is loaded into the target system, where it becomes available for business operations. Monitoring the loading process is crucial to track errors or issues, ensuring a smooth transition and timely availability of accurate data for decision-making. This helps maintain data integrity and addresses issues promptly for efficient system operation.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className=' d-flex align-items-center justify-content-center'>
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid rgb(55, 37, 153)' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-migration/MERAGE-AND-CONSOLIDATION-new.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-migration/MERAGE AND CONSOLIDATION.gif')} className='w-100' alt='MERAGE AND CONSOLIDATION' />
                            {/* <img src='https://smartintegraphics.com/assets/data-migration/MERAGE AND CONSOLIDATION.gif' className='w-100' alt='MERAGE AND CONSOLIDATION' /> */}
                        </Col>



                    </Row>
                </Container>
            </section>

            <section className='mt-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12} >
                            <div className="d-flex align-items-start justify-content-center">
                                <div className="data-migration-slide mt-2"></div>
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        Data Loading
                                    </h5>

                                    <p>
                                        Loading to the target system involves transferring the validated and transformed data into the production environment, making it ready for use in business operations. This step ensures that the data is accurately integrated and accessible for analysis, reporting, and decision-making. Monitoring the loading process is essential to identify and address any errors, discrepancies, or performance issues that may arise, ensuring a smooth and efficient data migration. Continuous monitoring helps maintain data accuracy, system integrity, and performance, minimizing disruptions and supporting reliable, timely insights for informed business decisions.
                                    </p>

                                </div>
                            </div>
                        </Col>
                        <Col lg={6}  >
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid rgb(55, 37, 153)' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-migration/Data-Loading-2.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-migration/Data Loading.gif')} className='w-100' alt='data-loading' />
                            {/* <img src='https://smartintegraphics.com/assets/data-migration/Data Loading.gif' className='w-100' alt='data-loading' /> */}
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className=' py-5'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12}>
                            <div className="d-flex align-items-start justify-content-center">
                                <div className="data-migration-slide mt-2"></div>
                                <div className="mx-3 text-start">
                                    <h5 className='head-fot-di'>
                                        Reconciliation
                                    </h5>

                                    <p>
                                        Post-migration validation involves performing thorough checks to verify data completeness, accuracy, and integrity in the target system. Creating detailed audit trails documents each migration step, including discrepancies and resolutions, ensuring transparency and accountability. Finally, engaging stakeholders in reviewing the final data ensures it meets business requirements, aligns with organizational goals, and addresses any concerns, confirming migration success, optimizing system performance, and ensuring readiness for operational use and long-term business operations.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className=' d-flex align-items-center justify-content-center'>
                            {/* <video controls autoPlay muted style={{ borderRadius: '10px', border: '3px solid rgb(55, 37, 153)' }} className=' w-100'>
                                <source
                                    src={require('../assets/data-migration/RECONCILIATION-new.mp4')}
                                    type="video/mp4"
                                />
                            </video> */}
                            <img src={require('../assets/data-migration/RECONCILIATION.gif')} className='w-100' alt='RECONCILIATION' />
                            {/* <img src='https://smartintegraphics.com/assets/data-migration/RECONCILIATION.gif' className='w-100' alt='RECONCILIATION' /> */}
                        </Col>
                    </Row>
                </Container>
            </section>


        </div>
    )
}
