import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './integration.css'
export const Integration = () => {
    return (
        <>

            <section style={{ marginTop: '5rem' }} className='py-3'>
                <Container>
                    <div className="data-integration-outer">
                        <div className="data-main-inner">
                            <Row className='align-items-center justify-content-center py-2'>
                                <Col lg={10}>
                                    <h5 className='head-fot-di'>
                                        Discover the Power of Data Integration
                                    </h5>
                                    <p>
                                        Smart Integraphics application offers a wide range of connectors for various data sources. These include file formats such as Excel, CSV, TXT, JSON, and XML. For databases, it supports SQL, Oracle, MySQL, MongoDB, and PostgreSQL. Additionally, it integrates seamlessly with popular ERP systems like SAP, Oracle, Microsoft Dynamics, S/4HANA, and IFS. With such a diverse set of integration options, Smart Integraphics provides users with extensive flexibility and versatility for their data analytics needs
                                    </p>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </Container>
            </section>

            <section>
                <Container>
                    <Row className='align-items-center justify-content-center d-flex'>
                        <Col lg={12} className='text-start data-third-sec '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Databases</h5>
                                    <p>
                                        Smart Integraphics' wide range of database connectors enables seamless integration with diverse systems, such as SQL, Oracle, MySQL, MongoDB, and PostgreSQL. This flexibility allows businesses to combine data from multiple sources for centralized and real-time analytics. It improves decision-making by providing accurate, up-to-date insights while ensuring data consistency. The system reduces manual data extraction efforts, saving time and costs. Additionally, it supports scalability and adapts to evolving business needs, enhancing overall efficiency
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="pt-3 pb-3" >
                <Container className="seamles-cilent-imag-1">
                    {/* <Row>
                        <Col lg={12} className='text-start data-third-sec pt-4 '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Databases</h5>
                                    <p>
                                        Smart Integraphics' wide range of database connectors enables seamless integration with diverse systems, such as SQL, Oracle, MySQL, MongoDB, and PostgreSQL. This flexibility allows businesses to combine data from multiple sources for centralized and real-time analytics. It improves decision-making by providing accurate, up-to-date insights while ensuring data consistency. The system reduces manual data extraction efforts, saving time and costs. Additionally, it supports scalability and adapts to evolving business needs, enhancing overall efficiency
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row> */}

                    <Row className="cilent-ico-sec mx-5">
                        <img src={require('../assets/data-integration/seamless-integration/mysql.png')} style={{ width: '18vh', position: 'relative' }} alt="mysal" />
                        <img src={require('../assets/data-integration/seamless-integration/ibmdb2.png')} style={{ width: '18vh' }} alt="ibm" />
                        <img src={require('../assets/data-integration/seamless-integration/sqllite.png')} style={{ width: '20vh' }} alt="sql-lite" />
                        <img src={require('../assets/data-integration/seamless-integration/azure-sql.png')} style={{ width: '21vh' }} alt="azure-sql" />
                        <img src={require('../assets/data-integration/seamless-integration/postgral.png')} style={{ width: '20vh' }} alt="postgral" />

                        {/*  */}

                        <img src={require('../assets/data-integration/seamless-integration/acess.jfif')} style={{ width: '13vh' }} alt="access" />
                        <img src={require('../assets/data-integration/seamless-integration/teradata.png')} style={{ width: '13vh' }} alt="tearadata" />
                        <img src={require('../assets/data-integration/seamless-integration/oracle-2.png')} style={{ width: '21vh' }} alt="oracle" />
                        <img src={require('../assets/data-integration/seamless-integration/Mongodb.png')} style={{ width: '21vh' }} alt="mongodb" />
                        <img src={require('../assets/data-integration/seamless-integration/MariaDB.png')} style={{ width: '21vh' }} alt="maria" />
                        <img src={require('../assets/data-integration/seamless-integration/ibm-informax.png')} style={{ width: '20vh' }} alt="ibm-informix" />
                        <img src={require('../assets/data-integration/seamless-integration/sybase.png')} style={{ width: '15vh' }} alt="sybase" />
                        <img src={require('../assets/data-integration/seamless-integration/mssql-2.webp')} style={{ width: '16vh' }} alt="mysql" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/Apache-Cassandra-Not-Only-SQL.png')} style={{ width: '15vh' }} alt="cassandra" />

                    </Row>


                </Container>
            </section>

            <section className="mt-4">
                <Container>
                    <Row className='align-items-center justify-content-center d-flex'>
                        <Col lg={12} className='text-start data-third-sec '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Enterprise Resource Planning (ERP) Systems:</h5>
                                    <p>
                                        Comprehensive data from ERP solutions like SAP, Oracle ERP, and Microsoft Dynamics is essential for managing core business processes such as finance, HR, and supply chain. These systems integrate data across departments, providing a unified view that improves decision-making, enhances efficiency, and fosters collaboration. By leveraging ERP data, businesses can optimize resources, reduce costs, and drive growth.
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-1">
                <Container className="seamles-cilent-imag-1">
                    {/* <Row className='align-items-center justify-content-center d-flex'>
                        <Col lg={12} className='text-start data-third-sec pt-4 '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Enterprise Resource Planning (ERP) Systems:</h5>
                                    <p>
                                        Comprehensive data from ERP solutions like SAP, Oracle ERP, and Microsoft Dynamics is essential for managing core business processes such as finance, HR, and supply chain. These systems integrate data across departments, providing a unified view that improves decision-making, enhances efficiency, and fosters collaboration. By leveraging ERP data, businesses can optimize resources, reduce costs, and drive growth.
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row> */}
                    <Row className="support-files-icoo ">
                        <img src={require('../assets/data-integration/DAtabase-Icons/1.png')} style={{ width: '25vh' }} alt="sap-s4" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/icon-sap-ecc.png')} style={{ width: '18vh' }} alt="sap-ece" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/Dynamics.png')} style={{ width: '22vh' }} alt="Microsoft-Dynamics" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/oracle-e-business.png')} style={{ width: '20vh' }} alt="Oracle-e" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/IFS.png')} style={{ width: '18vh' }} alt="IFS" />
                    </Row>
                </Container>
            </section>

            <section className="mt-4">
                <Container>
                    <Row className='align-items-center justify-content-center d-flex'>
                        <Col lg={12} className='text-start data-third-sec '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Files</h5>
                                    <p>
                                        Unstructured and semi-structured data from file formats like CSV, Excel, JSON, and XML contain valuable insights for analysis. These formats capture diverse information, from simple tables to complex structures, enabling businesses to uncover patterns, identify trends, and make informed decisions. Properly managing and integrating this data with structured data enhances analysis and drives better performance and competitive advantage.
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-4" >
                <Container className="seamles-cilent-imag-1">
                    {/* <Row className='align-items-center justify-content-center d-flex'>
                        <Col lg={12} className='text-start data-third-sec pt-4'>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Files</h5>
                                    <p>
                                        Unstructured and semi-structured data from file formats like CSV, Excel, JSON, and XML contain valuable insights for analysis. These formats capture diverse information, from simple tables to complex structures, enabling businesses to uncover patterns, identify trends, and make informed decisions. Properly managing and integrating this data with structured data enhances analysis and drives better performance and competitive advantage.
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row> */}
                    <Row className="file-img-sec pb-3">
                        <img src={require('../assets/data-integration/DAtabase-Icons/csv.png')} style={{ width: '14vh' }} alt="csv" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/x-cel.png')} style={{ width: '16vh' }} alt="XCel" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/XLS-File.png')} style={{ width: '19vh' }} alt="XCel-files" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/json.webp')} style={{ width: '13vh' }} alt="json" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/xml.png')} style={{ width: '13vh' }} alt="xml" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/pdf.jpg')} style={{ width: '11vh' }} alt="pdf" />
                    </Row>
                </Container>
            </section>

            <section className="mt-4">
                <Container>
                    <Row className='align-items-center justify-content-center d-flex'>
                        <Col lg={12} className='text-start data-third-sec  '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Cloud Storage</h5>
                                    <p>
                                        Data stored in cloud solutions like AWS, Google Cloud, and Azure provides flexibility and scalability, making it easy to integrate diverse data sources. These platforms offer secure, cost-effective storage and real-time access, enabling businesses to optimize operations and make faster, data-driven decisions. Additionally, cloud solutions support seamless collaboration and innovation across teams, driving overall business growth.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-4">
                <Container className="seamles-cilent-imag-1">
                    {/* <Row className='align-items-center justify-content-center d-flex'>
                        <Col lg={12} className='text-start data-third-sec pt-4 '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Cloud Storage</h5>
                                    <p>
                                        Data stored in cloud solutions like AWS, Google Cloud, and Azure provides flexibility and scalability, making it easy to integrate diverse data sources. These platforms offer secure, cost-effective storage and real-time access, enabling businesses to optimize operations and make faster, data-driven decisions. Additionally, cloud solutions support seamless collaboration and innovation across teams, driving overall business growth.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    <Row className="image-vide-img-se pb-3">
                        <img src={require('../assets/data-integration/DAtabase-Icons/amazon.png')} style={{ width: '17vh' }} alt="amazon" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/google-cloud.png')} style={{ width: '18vh' }} alt="google-cloud" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/azure.png')} style={{ width: '18vh' }} alt="azure" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/onedrive.png')} style={{ width: '20vh' }} alt="onedrive" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/ftp.webp')} style={{ width: '16vh' }} alt="ftp" />
                    </Row>
                </Container>
            </section>

            <section className="mt-4">
                <Container>
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col lg={12} className='text-start data-third-sec '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3 ">
                                    <h5 className='head-fot-di'>Image and Videos</h5>
                                    <p>
                                        Multimedia data from video files and streaming platforms provides valuable insights through visual content analysis and audience engagement metrics. It helps businesses uncover trends, optimize content strategies, and enhance customer experiences, driving greater engagement and brand loyalty. By analyzing this data, companies can tailor content to specific audiences, improving targeting and increasing the effectiveness of marketing campaigns.
                                    </p>
                                </div>

                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>

            <section className="mt-4" >
                <Container className="seamles-cilent-imag-1">
                    {/* <Row className='d-flex align-items-center justify-content-center'>
                        <Col lg={12} className='text-start data-third-sec pt-4 '>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3 ">
                                    <h5 className='head-fot-di'>Image and Videos</h5>
                                    <p>
                                        Multimedia data from video files and streaming platforms provides valuable insights through visual content analysis and audience engagement metrics. It helps businesses uncover trends, optimize content strategies, and enhance customer experiences, driving greater engagement and brand loyalty. By analyzing this data, companies can tailor content to specific audiences, improving targeting and increasing the effectiveness of marketing campaigns.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    <Row className="image-vide-img-se pb-3">
                        <img src={require('../assets/data-integration/DAtabase-Icons/image.png')} style={{ width: '14vh' }} alt="image-v" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/video.png')} style={{ width: '16vh' }} alt="video" />
                    </Row>
                </Container>
            </section>

            <section className="mt-4">
                <Container>
                    <Row className='align-items-center justify-content-center d-flex'>

                        <Col lg={12} className='text-start data-third-sec'>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Social media</h5>
                                    <p>
                                        Data extraction from social media platforms like Facebook, YouTube, LinkedIn, and Twitter uses their APIs to gather user profiles, posts, and engagement metrics. This enables businesses to analyze trends, track sentiment, refine marketing strategies, and drive engagement by understanding customer behavior and preferences. Additionally, it allows companies to identify emerging trends, monitor brand health, and create more targeted campaigns.
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="mt-4 mb-2">
                <Container className="seamles-cilent-imag-1">
                    {/* <Row className='align-items-center justify-content-center d-flex'>

                        <Col lg={12} className='text-start data-third-sec pt-4'>
                            <div className="d-flex align-items-start justify-contnet-between">
                                <div className="data-slide-integra"></div>
                                <div className="mx-3">
                                    <h5 className='head-fot-di'>Social media</h5>
                                    <p>
                                        Data extraction from social media platforms like Facebook, YouTube, LinkedIn, and Twitter uses their APIs to gather user profiles, posts, and engagement metrics. This enables businesses to analyze trends, track sentiment, refine marketing strategies, and drive engagement by understanding customer behavior and preferences. Additionally, it allows companies to identify emerging trends, monitor brand health, and create more targeted campaigns.
                                    </p>
                                </div>

                            </div>

                        </Col>
                    </Row> */}

                    <Row className="social-meida-img-sec pb-3">
                        <img src={require('../assets/data-integration/DAtabase-Icons/linkedin.png')} style={{ width: '14vh' }} alt="linkedin" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/facebook.png')} style={{ width: '14vh' }} alt="facebook" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/instagram.png')} style={{ width: '14vh' }} alt="Instagram" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/youtube.png')} style={{ width: '14vh' }} alt="youtube" />
                        <img src={require('../assets/data-integration/DAtabase-Icons/twitter.png')} style={{ width: '14vh' }} alt="twitter" />
                    </Row>
                </Container>
            </section>
        </>
    )
}