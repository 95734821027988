import React from 'react'
import { Col, Row } from 'react-bootstrap'
import './footer.css'
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import MailIcon from '@mui/icons-material/Mail';
// import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';

import XIcon from '@mui/icons-material/X';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';


export const Footer = () => {
    return (
        <div>
            {/* <section className='footer-main-sec py-3'>
                <Container>
                    <Row className=''>
                        <Col lg={4} sm={7} xs={12}>
                            <img src={require('../assets/header/Smart-IG-White-LOGO.png')} style={{ width: '100%' }} alt="logo" />

                            <div className="power-data-footer mt-3">
                                <h2 style={{ fontSize: '1.5rem' }}>Powered By Data Science.</h2>
                                <p >
                                    Argumented By AI. Built For People.
                                </p>
                            </div>

                        </Col>

                        <Col lg={4}>

                            <div className="">
                                <div className="footer-detail mt-3">
                                    <LocationOnIcon className="locat-ico" />
                                    <p>
                                        Dubai Silicon Oasis, Mirpur 2 DDP,Building A2, United Arab Emirates
                                    </p>
                                </div>

                                <div className="footer-detail mt-2 ">
                                    <MailIcon className="mail-ico" />
                                    <h6>
                                        info@integraldataanalytics.com
                                    </h6>
                                </div>

                                <div className="footer-detail mt-2">
                                    <PhoneIcon className="call-ico" />
                                    <h6>+971 568421293</h6>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} className='mt-3 '>
                            <Link className='footer-cotac' to="contact-us">Contact Us</Link>
                            <p>
                                Privacy Policy
                            </p>
                        </Col>

                    </Row>
                </Container>
            </section> */}

            <section className='footer-main-sec py-1'>
                {/* <Container> */}
                <Row>
                    <Col lg={7} className='d-flex align-items-start justify-content-start' style={{ borderRight: '1px solid #fff', flexDirection: 'column' }}>
                        <img src={require('../assets/header/Smart-IG-White-LOGO.png')} style={{ width: '40%' }} alt="logo" />
                        <div className="d-flex gap-3 text-center align-items-center justify-content-start px-3 pb-4  socio-ico-foo mt-4"  >
                            <Link to='https://www.linkedin.com/company/integral-analytics-solutions/posts/?feedView=all' target='blank'>
                                <div className="form-soci-icon-footer">
                                    <LinkedInIcon className="facebook-footer" />
                                </div>
                            </Link>

                            <Link to='https://www.youtube.com/@Smart_IG' target='blank'>
                                <div className="form-soci-icon-footer">
                                    <YouTubeIcon className="youtube-footer" />
                                </div>
                            </Link>

                            <Link to='https://www.facebook.com/IntegralAnalyticsSolutions/' target='blank'>
                                <div className="form-soci-icon-footer">
                                    <FacebookOutlinedIcon className="facebook-footer" />
                                </div>
                            </Link>

                            <Link to='https://www.instagram.com/integralanalytics/' target='blank'>
                                <div className="form-soci-icon-footer">
                                    <InstagramIcon className="facebook-footer" />
                                </div>
                            </Link>

                            <Link to='https://x.com/i/flow/login?redirect_after_login=%2FIntegralSmartIG' target='blank'>
                                <div className="form-soci-icon-footer">
                                    <XIcon className="twitter-footer" />
                                </div>
                            </Link>

                        </div>
                    </Col>

                    <Col lg={2} className='text-start' style={{ borderRight: '1px solid #fff' }}>
                        <div className="">
                            <Link to='data-integration' className='footer-cotac text-start'>
                                Data Integration
                            </Link>
                        </div>
                        <div className="mt-2">
                            <Link to='data-migration' className='footer-cotac text-start'>
                                Data Migration
                            </Link>
                        </div>
                        <div className="mt-2">
                            <Link to='data-analytics' className='footer-cotac text-start'>
                                Data Analytics
                            </Link>
                        </div>
                        <div className="mt-2">
                            <Link to='imirai' className='footer-cotac text-start'>
                                iMirAI
                            </Link>
                        </div>


                        {/* <div className="">
                            <div className="footer-detail mt-3">
                                <LocationOnIcon className="locat-ico" />
                                <p>
                                    Dubai Silicon Oasis, Mirpur 2 DDP,Building A2, United Arab Emirates
                                </p>
                            </div>

                            <div className="footer-detail mt-2 ">
                                <MailIcon className="mail-ico" />
                                <h6>
                                    info@integraldataanalytics.com
                                </h6>
                            </div>

                            <div className="footer-detail mt-2">
                                <PhoneIcon className="call-ico" />
                                <h6>+971 568421293</h6>
                            </div>
                        </div> */}
                    </Col>

                    <Col lg={3} className='text-start'>
                        <div className="">
                            <Link className='footer-cotac text-start' to="contact-us">Contact Us</Link>
                        </div>
                        <div className="mt-2">
                            <Link className='footer-cotac text-start' to="privacy"> Privacy Policy</Link>
                        </div>
                        <div className="power-data-footer mt-2">
                            <h2 style={{ fontSize: '1.2rem' }}>Powered By Data Science.</h2>
                            <p>
                            Augmented By AI. Built For People.
                            </p>
                        </div>

                    </Col>
                </Row>
                {/* </Container> */}
            </section>


        </div>
    )
}
