import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import './header.css'
import { Link } from 'react-router-dom'

export const Header = () => {
    return (
        <div>

            {/* <Navbar className=' head-main'>
                <Container fluid>
                    <Navbar.Brand href="#home" className='head-main-image-sec' >
                        <img src={require("./assets/header/smart-IG-logo.png")} alt='main-logo' style={{ width: '100%' }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                    <Nav className="mt-auto new-nav-head">
                        <Link className='nav-item nav-link  header-butto' to="Home">Home</Link>
                        <Link className='nav-item nav-link  header-butto' to="data-integration">Data Integration</Link>
                        <Link className='nav-item nav-link  header-butto' to="#">Data Migration</Link>
                        <Link className='nav-item nav-link  header-butto' to="data-analytics">Data Analytics</Link>
                        <Link className='nav-item nav-link  header-butto' to="pricing">Pricing</Link>
                        <Link className='nav-item nav-link  header-butto' to="pricing">Contact Us</Link>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}



            <Navbar expand="lg" className='head-main' fixed='top'  >
                <Container fluid>
                    <Navbar.Brand className='head-main-image-sec'>
                        <Link to='/'>
                            <img src={require("../assets/header/smart-IG-logo.png")} alt='main-logo' className='w-100' />
                        </Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="ms-auto  my-lg-0" navbarScroll>
                            <Link className='nav-item nav-link me-2 header-butto' to="/">Home</Link>
                            <Link className='nav-item nav-link me-2 header-butto' to="integration">Data Integration</Link>
                            <Link className='nav-item nav-link me-2  header-butto' to="data-migration">Data Migration</Link>
                            <Link className='nav-item nav-link  me-2 header-butto' to="data-analytics">Data Analytics</Link>
                            <Link className='nav-item nav-link  me-2 header-butto' to="imirai">iMirAI</Link>
                            <Link className='nav-item nav-link me-2 header-butto' to="pricing">Pricing</Link>
                            <Link className='nav-item nav-link me-2  header-butto' to="About-Us">About Us</Link>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
